import { collection, getDocs, query, orderBy } from 'firebase/firestore'; 
import { db } from './firebase'; 

// Fetch client data, including the current program and the next workout based on completed tags
export const fetchClientData = async (clientId) => {
  try {
    const assignedProgramsRef = collection(db, `clients/${clientId}/assignedPrograms`);
    const assignedProgramsSnap = await getDocs(assignedProgramsRef);

    if (assignedProgramsSnap.empty) {
      console.error(`No assigned program found for client with ID: ${clientId}`);
      return { program: null, firstWorkout: null };
    }

    // Find the current assigned program
    const currentProgramDoc = assignedProgramsSnap.docs.find(doc => doc.data().status === 'current');
    if (!currentProgramDoc) {
      console.error('No current program found.');
      return { program: null, firstWorkout: null };
    }

    const programData = currentProgramDoc.data();
    const assignedProgramId = currentProgramDoc.id;

    const mesoCyclesRef = collection(db, `clients/${clientId}/assignedPrograms/${assignedProgramId}/mesoCycles`);
    const mesoCyclesSnap = await getDocs(mesoCyclesRef);

    const firstWorkout = await getCurrentWorkout(programData, mesoCyclesSnap, clientId, assignedProgramId);

    return {
      program: programData,
      firstWorkout: { ...firstWorkout, programId: assignedProgramId },
    };
  } catch (error) {
    console.error('Error fetching client program data:', error);
    return { program: null, firstWorkout: null };
  }
};

// Helper function to get the current workout based on completed tags and exercise order
const getCurrentWorkout = async (programData, mesoCyclesSnap, clientId, assignedProgramId) => {
  const completedTags = programData.completedTags || [];
  const trainingFrequency = programData.workoutFrequency || 3;

  console.log('Training Frequency:', trainingFrequency);
  console.log('Completed Tags:', completedTags);

  const lastCompletedTag = completedTags.sort((a, b) => {
    const [aM, aW, aX] = a.split('_').map(Number);
    const [bM, bW, bX] = b.split('_').map(Number);
    return aX - bX || aM - bM || aW - bW;
  }).pop();

  let nextMesoCycleNumber, nextWorkoutNumber, nextWeek;

  if (lastCompletedTag) {
    const [lastM, lastW, lastX] = lastCompletedTag.split('_').map(Number);
    nextMesoCycleNumber = lastM;
    nextWorkoutNumber = lastW + 1;
    nextWeek = lastX;

    if (nextWorkoutNumber > trainingFrequency) {
      nextWeek += 1;
      nextWorkoutNumber = 1;

      if (nextWeek > 4) {
        nextMesoCycleNumber += 1;
        nextWeek = 1;
      }
    }

    let nextTag = `${String(nextMesoCycleNumber).padStart(3, '0')}_${String(nextWorkoutNumber).padStart(3, '0')}_${String(nextWeek).padStart(3, '0')}`;
    console.log('Next Tag to be checked:', nextTag);

    while (completedTags.includes(nextTag)) {
      nextWorkoutNumber += 1;
      if (nextWorkoutNumber > trainingFrequency) {
        nextWeek += 1;
        nextWorkoutNumber = 1;
        if (nextWeek > 4) {
          nextMesoCycleNumber += 1;
          nextWeek = 1;
        }
      }
      nextTag = `${String(nextMesoCycleNumber).padStart(3, '0')}_${String(nextWorkoutNumber).padStart(3, '0')}_${String(nextWeek).padStart(3, '0')}`;
    }
  } else {
    nextMesoCycleNumber = 1;
    nextWorkoutNumber = 1;
    nextWeek = 1;
  }

  const nextMesoCycleDoc = mesoCyclesSnap.docs.find(doc => doc.data().mesocycleNumber === nextMesoCycleNumber);
  if (!nextMesoCycleDoc) return null;

  const nextWorkoutsRef = collection(
    db,
    `clients/${clientId}/assignedPrograms/${assignedProgramId}/mesoCycles/${nextMesoCycleDoc.id}/workouts`
  );
  const nextWorkoutsSnap = await getDocs(nextWorkoutsRef);

  const nextWorkoutDoc = nextWorkoutsSnap.docs.find(doc => doc.data().workoutNumber === nextWorkoutNumber);
  if (!nextWorkoutDoc) {
    console.error(`No workout found for MesoCycle ${nextMesoCycleNumber}, Workout ${nextWorkoutNumber}`);
    return null;
  }

  // Fetch exercises sorted by exerciseOrder
  const exercisesRef = collection(
    db,
    `clients/${clientId}/assignedPrograms/${assignedProgramId}/mesoCycles/${nextMesoCycleDoc.id}/workouts/${nextWorkoutDoc.id}/exercises`
  );
  const exercisesQuery = query(exercisesRef, orderBy('exerciseOrder'));
  const exercisesSnap = await getDocs(exercisesQuery);

  const exercises = exercisesSnap.docs.map(exerciseDoc => ({
    id: exerciseDoc.id,
    ...exerciseDoc.data(),
  }));

  console.log('Exercises fetched (in order):', exercises);

  return {
    workoutId: nextWorkoutDoc.id,
    mesoCycleId: nextMesoCycleDoc.id,
    mesoCycleNumber: nextMesoCycleNumber,
    workoutNumber: nextWorkoutNumber,
    week: nextWeek,
    exercises,
  };
};