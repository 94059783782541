import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

const ClientInfo = ({ clientId }) => {
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (clientId) {
      fetchClientData();
    }
  }, [clientId]);

  const fetchClientData = async () => {
    try {
      const docRef = doc(db, 'clients', clientId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setClientData(docSnap.data());
      } else {
        console.error('No client data found for ID:', clientId);
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading client data...</p>;
  }

  if (!clientData) {
    return <p>No client data found for ID: {clientId}</p>;
  }

  return (
    <div>
      <h2>Client Information</h2>
      <p>Full Name: {clientData.fullName}</p>
      <p>Email: {clientData.email}</p>
      <p>Gender: {clientData.gender}</p>
      <p>Height: {clientData.height}</p>
    </div>
  );
};

export default ClientInfo;
