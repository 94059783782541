import React, { useState, useEffect } from 'react';
import ProgramCreator from './ProgramCreator';
import ProgramDatabase from './ProgramDatabase';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import './ProgramManager.css';

const ProgramManager = () => {
  const [programs, setPrograms] = useState([]);

  // Function to fetch programs from Firestore
  const fetchPrograms = async () => {
    const programsCollection = collection(db, 'programs');
    const querySnapshot = await getDocs(programsCollection);
    const programsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setPrograms(programsList);
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const handleProgramCreated = async () => {
    await fetchPrograms(); // Refresh the program list after a new program is created
  };

  return (
    <div className="program-manager-container">
      <div className="program-manager-header">
        <h2>Program Manager</h2>
        <div className="program-manager-actions">
          <button onClick={() => console.log('Add new program clicked')}>
            Add New Program
          </button>
          <button onClick={() => console.log('View reports clicked')}>
            View Reports
          </button>
        </div>
      </div>

      {/* Program Creator Form */}
      <div className="program-creation-form">
        <ProgramCreator onProgramCreated={handleProgramCreated} />
      </div>

      {/* Program List */}
      <h3>Existing Programs</h3>
      <ProgramDatabase programs={programs} onProgramsUpdated={fetchPrograms} />
    </div>
  );
};

export default ProgramManager;