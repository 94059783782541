import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";

const LandingPage = () => {
  return (
    <div className="landing-page">
      {/* Navbar */}
      <nav className="navbar">
        <Link to="/" className="logo">Myopatterns</Link>
        <div className="nav-links">
          <Link to="/features">Features</Link>
          <Link to="/pricing">Pricing</Link>
          <Link to="/login">Login</Link>
          <Link to="/signup" className="cta-button">Sign Up</Link>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Intelligent Movement Analysis Meets Proactive Dynamic Programming</h1>
          <p>
            Leverage AI-powered assessments to optimize client training. Automatically score movements and assign personalized exercise variations with our Proactive Dynamic Programming system.
          </p>
          <div className="hero-buttons">
            <Link to="/start" className="cta-button">Start Free Trial</Link>
            <Link to="/how-it-works" className="secondary-button">See How It Works</Link>
          </div>
        </div>
        <div className="hero-image">
          <img src="/MPMultilogo_Cleaned copy.png" alt="MyoPatterns Logo" />
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <h2>How Proactive Dynamic Programming (PDP) Works</h2>
        <p>Assess, Analyze, and Adapt – Personalized Training, Powered by AI.</p>
        <div className="steps">
          <div className="step">
            <h3>Step 1: Conduct a Movement Screening</h3>
            <p>Perform screens like the Active Straight Leg Raise and Toe Touch.</p>
          </div>
          <div className="step">
            <h3>Step 2: AI Analysis & Scoring</h3>
            <p>Our AI automatically scores your clients’ movements on a scale of 0 to 3.</p>
          </div>
          <div className="step">
            <h3>Step 3: Personalized Program Assignment</h3>
            <p>Based on scores, the system assigns a tailored exercise variation to match your clients' capabilities.</p>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits">
        <h2>Why Choose Proactive Dynamic Programming?</h2>
        <ul>
          <li><strong>Efficiency:</strong> Automate assessments to save time.</li>
          <li><strong>Accuracy:</strong> Consistent and objective scoring powered by AI.</li>
          <li><strong>Personalization:</strong> Tailored exercise programs for every client.</li>
          <li><strong>Scalability:</strong> Train more clients in less time.</li>
        </ul>
      </section>
    </div>
  );
};

export default LandingPage;