import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const ClientDashboard = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch clients from Firestore
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'clients'));
        const clientList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(clientList);
      } catch (err) {
        console.error('Error fetching clients:', err);
        setError('Failed to fetch clients');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Display loading message while fetching data
  if (loading) return <div>Loading...</div>;

  // Display error message if there was an issue
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h1>Client Dashboard</h1>
      {clients.length > 0 ? (
        <ul>
          {clients.map(client => (
            <li key={client.id}>
              <Link to={`/client/${client.id}`}>{client.fullName || client.name}</Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>No clients found.</p>
      )}

      {/* Add a link to the intake form */}
      <div>
        <Link to="/intake-form">
          <button>New Client Intake Form</button>
        </Link>
      </div>
    </div>
  );
};

export default ClientDashboard;