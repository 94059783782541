import React, { useState, useEffect } from 'react';
import { storage } from './firebase'; // Import Firebase storage
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage functions

const AddExerciseForm = ({ onAddExercise, exerciseToEdit, onUpdateExercise }) => {
  const [formState, setFormState] = useState({
    name: '',
    equipment: '',
    pattern: '',
    muscles: [],
    positions: [], // New state for positions
    instructions: '',
    photo: '',
    video: '',
  });

  const [imageFile, setImageFile] = useState(null); // State to hold the selected file
  const [uploading, setUploading] = useState(false); // State for tracking upload status

  // Define the list of muscle groups for the checkboxes
  const muscleGroups = [
    'Chest', 'Back', 'Shoulders', 'Biceps', 'Triceps',
    'Quadriceps', 'Hamstrings', 'Glutes', 'Calves',
    'Forearms', 'Abdominals', 'Obliques', 'Rotator Cuff',
  ];

  // Define the list of positions for the checkboxes, including the new options
  const positions = [
    'Standing', 'Seated', 'Tall Kneeling', 'Half Kneeling', 
    'Quadruped', 'Split Stance', 'Step Stance', 'Prone', 
    'Supine', 'Hanging', 'Hinged', 'Side Lying'
  ];

  useEffect(() => {
    if (exerciseToEdit) {
      setFormState({
        ...exerciseToEdit,
        positions: exerciseToEdit.positions || [], // Ensure positions is always an array
      });
    } else {
      setFormState({
        name: '',
        equipment: '',
        pattern: '',
        muscles: [],
        positions: [], // Initialize positions as an empty array
        instructions: '',
        photo: '',
        video: '',
      });
    }
  }, [exerciseToEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // Handle muscle group checkbox changes
  const handleMuscleChange = (e) => {
    const { value, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      muscles: checked
        ? [...prevState.muscles, value] // Add muscle to the array
        : prevState.muscles.filter((muscle) => muscle !== value), // Remove muscle from the array
    }));
  };

  // Handle position checkbox changes
  const handlePositionChange = (e) => {
    const { value, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      positions: checked
        ? [...prevState.positions, value] // Add position to the array
        : prevState.positions.filter((position) => position !== value), // Remove position from the array
    }));
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  // Upload image to Firebase Storage
  const uploadImage = async () => {
    if (!imageFile) return '';

    try {
      setUploading(true);
      const storageRef = ref(storage, `exerciseImages/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile); // Upload the file
      const downloadURL = await getDownloadURL(storageRef); // Get the URL
      setUploading(false);
      return downloadURL; // Return the download URL
    } catch (error) {
      console.error('Error uploading image: ', error);
      setUploading(false);
      return '';
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    let imageURL = formState.photo;

    // Upload the image if a new file is selected
    if (imageFile) {
      imageURL = await uploadImage();
    }

    const exerciseData = {
      ...formState,
      photo: imageURL, // Use the uploaded image URL or existing URL
    };

    if (exerciseToEdit) {
      onUpdateExercise(exerciseData);
    } else {
      onAddExercise(exerciseData);
    }

    setFormState({
      name: '',
      equipment: '',
      pattern: '',
      muscles: [],
      positions: [], // Reset positions after form submission
      instructions: '',
      photo: '',
      video: '',
    });
    setImageFile(null); // Reset the file input
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>{exerciseToEdit ? 'Edit Exercise' : 'Add New Exercise'}</h2>
      
      {/* Exercise Name */}
      <div>
        <label>Exercise Name:</label>
        <input type="text" name="name" value={formState.name} onChange={handleInputChange} required />
      </div>
      
      {/* Equipment */}
      <div>
        <label>Equipment:</label>
        <select name="equipment" value={formState.equipment} onChange={handleInputChange} required>
          <option value="">Select Equipment</option>
          <option value="Barbell">Barbell</option>
          <option value="Dumbbell">Dumbbell</option>
          <option value="Cable">Cable</option>
          <option value="Bodyweight">Bodyweight</option>
        </select>
      </div>
      
      {/* Functional Pattern */}
      <div>
        <label>Functional Pattern:</label>
        <select name="pattern" value={formState.pattern} onChange={handleInputChange} required>
          <option value="">Select Pattern</option>
          <option value="Vertical Push">Vertical Push</option>
          <option value="Horizontal Push">Horizontal Push</option>
          <option value="Vertical Pull">Vertical Pull</option>
          <option value="Horizontal Pull">Horizontal Pull</option>
          <option value="Squat">Squat</option>
          <option value="Hinge">Hinge</option>
          <option value="Lunge">Lunge</option>
          <option value="Sagittal Core">Sagittal Core</option>
          <option value="Frontal Core">Frontal Core</option>
          <option value="Transverse Core">Transverse Core</option>
          <option value="Isolation">Isolation</option>
        </select>
      </div>
      
      {/* Muscles Involved */}
      <div>
        <label>Muscles Involved:</label>
        <div>
          {muscleGroups.map((muscle) => (
            <div key={muscle}>
              <input
                type="checkbox"
                value={muscle}
                checked={formState.muscles.includes(muscle)}
                onChange={handleMuscleChange}
              />
              <label>{muscle}</label>
            </div>
          ))}
        </div>
      </div>

      {/* Positions */}
      <div>
        <label>Position:</label>
        <div>
          {positions.map((position) => (
            <div key={position}>
              <input
                type="checkbox"
                value={position}
                checked={formState.positions.includes(position)}
                onChange={handlePositionChange}
              />
              <label>{position}</label>
            </div>
          ))}
        </div>
      </div>
      
      {/* Written Instructions */}
      <div>
        <label>Written Instructions:</label>
        <textarea name="instructions" value={formState.instructions} onChange={handleInputChange} required></textarea>
      </div>
      
      {/* Photo URL */}
      <div>
        <label>Photo URL:</label>
        <input type="url" name="photo" value={formState.photo} onChange={handleInputChange} />
      </div>
      
      {/* Upload Image */}
      <div>
        <label>Upload Image:</label>
        <input type="file" accept="image/*" onChange={handleFileChange} />
      </div>
      {uploading && <p>Uploading image...</p>}
      
      {/* Video URL */}
      <div>
        <label>Video URL:</label>
        <input type="url" name="video" value={formState.video} onChange={handleInputChange} />
      </div>
      
      {/* Submit Button */}
      <button type="submit">{exerciseToEdit ? 'Update Exercise' : 'Add Exercise'}</button>
    </form>
  );
};

export default AddExerciseForm;
