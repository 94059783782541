import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase'; // Ensure this path is correct for your Firebase setup
import './App.css'; // Import the CSS for styling

// Reusable Checkbox Component
const CheckboxGroup = ({ options, selected, onChange }) => (
  <div>
    {options.map((option) => (
      <div key={option}>
        <label>
          <input
            type="checkbox"
            value={option}
            checked={selected.includes(option)}
            onChange={(e) => onChange(e)}
          /> {option}
        </label>
      </div>
    ))}
  </div>
);

// Reusable Radio Button Component
const RadioGroup = ({ question, name, selected, onChange }) => (
  <div>
    <label>{question}</label>
    <div className="risk-factor-options">
      <label><input type="radio" name={name} value="Yes" checked={selected === 'Yes'} onChange={(e) => onChange(e)} /> Yes</label>
      <label><input type="radio" name={name} value="No" checked={selected === 'No'} onChange={(e) => onChange(e)} /> No</label>
    </div>
  </div>
);

const DataEntry = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    gender: '',
    birthDate: '',
    height: '',
    cardiovascularDiseases: [],
    pulmonaryDiseases: [],
    metabolicDiseases: [],
    symptoms: [],
    riskFactors: {},
    musculoskeletalIssues: {}
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e, field) => {
    const { value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: checked
        ? [...prevData[field], value]
        : prevData[field].filter((item) => item !== value)
    }));
  };

  const handleRadioChange = (e, field) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      riskFactors: { ...prevData.riskFactors, [field]: value }
    }));
  };

  const handleMusculoskeletalChange = (e, joint, type) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      musculoskeletalIssues: {
        ...prevData.musculoskeletalIssues,
        [joint]: { ...prevData.musculoskeletalIssues[joint], [type]: checked }
      }
    }));
  };

  // Form validation
  const validateForm = () => {
    let formErrors = {};
    if (!formData.fullName) formErrors.fullName = 'Full Name is required';
    if (!formData.email) formErrors.email = 'Email is required';
    if (!formData.birthDate) formErrors.birthDate = 'Birthdate is required';
    if (!formData.gender) formErrors.gender = 'Gender is required';
    if (!formData.height) formErrors.height = 'Height is required';
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    try {
      await addDoc(collection(db, 'clients'), formData);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const resetForm = () => {
    setFormData({
      fullName: '',
      email: '',
      gender: '',
      birthDate: '',
      height: '',
      cardiovascularDiseases: [],
      pulmonaryDiseases: [],
      metabolicDiseases: [],
      symptoms: [],
      riskFactors: {},
      musculoskeletalIssues: {}
    });
    setIsSubmitted(false);
    setErrors({});
  };

  const joints = ["Head", "Neck", "Shoulder", "Upper Back", "Elbow", "Wrist", "Hand", "Abdomen", "Lower Back", "Hip", "Knee", "Ankle", "Foot"];
  const cardiovascularOptions = [
    "Heart Attack", 
    "Coronary Artery Disease", 
    "Peripheral Artery Disease", 
    "Congestive Heart Failure", 
    "Arrhythmia", 
    "Hypertension", 
    "Atrial Fibrillation", 
    "Cardiomyopathy"
  ];
  const pulmonaryOptions = [
    "COPD", 
    "Asthma", 
    "Pulmonary Hypertension", 
    "Cystic Fibrosis", 
    "Interstitial Lung Disease", 
    "Chronic Bronchitis", 
    "Emphysema", 
    "Pneumonia"
  ];
  const metabolicOptions = [
    "Diabetes Mellitus (Type 1 or 2)", 
    "Hyperthyroidism", 
    "Hypothyroidism", 
    "Metabolic Syndrome", 
    "PCOS", 
    "NAFLD", 
    "Gout"
  ];
  const symptomOptions = [
    "Pain or discomfort in areas that may result from ischemia",
    "Shortness of breath at rest or with mild exertion",
    "Dizziness or fainting",
    "Shortness of breath while laying down",
    "Ankle Edema",
    "Heart Palpitations or Tachycardia",
    "Unusual fatigue or shortness of breath with activity",
    "Known heart murmurs"
  ];

  return (
    <div>
      <h1>Medical History Questionnaire</h1>
      {isSubmitted ? (
        <div>
          <h2>Submission Successful!</h2>
          <button onClick={resetForm}>Submit Another</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {/* Section 1: General Information */}
          <h2>Section 1: General Information</h2>
          <label>Full Name:
            <input type="text" value={formData.fullName} className="indent" onChange={(e) => setFormData({ ...formData, fullName: e.target.value })} />
            {errors.fullName && <p className="error">{errors.fullName}</p>}
          </label>
          <br />
          <label>Email:
            <input type="email" value={formData.email} className="indent" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
            {errors.email && <p className="error">{errors.email}</p>}
          </label>
          <br />
          <label>Gender:
            <select value={formData.gender} className="indent" onChange={(e) => setFormData({ ...formData, gender: e.target.value })}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Prefer not to say">Prefer not to say</option>
            </select>
            {errors.gender && <p className="error">{errors.gender}</p>}
          </label>
          <br />
          <label>Birthdate:
            <input type="date" value={formData.birthDate} className="indent" onChange={(e) => setFormData({ ...formData, birthDate: e.target.value })} />
            {errors.birthDate && <p className="error">{errors.birthDate}</p>}
          </label>
          <br />
          <label>Height:
            <select value={formData.height} className="indent" onChange={(e) => setFormData({ ...formData, height: e.target.value })}>
              <option value="">Select Height</option>
              {[...Array(48).keys()].map((i) => (
                <option key={i} value={`${4 + Math.floor(i / 12)}' ${i % 12}"`}>{`${4 + Math.floor(i / 12)}' ${i % 12}"`}</option>
              ))}
            </select>
            {errors.height && <p className="error">{errors.height}</p>}
          </label>
          <br />

          {/* Section 2: Risk Stratification */}
          <h2>Section 2: Risk Stratification</h2>
          <h3>Please list all Cardiovascular Diseases you have been diagnosed with:</h3>
          <CheckboxGroup options={cardiovascularOptions} selected={formData.cardiovascularDiseases} onChange={(e) => handleChange(e, 'cardiovascularDiseases')} />
          <br />

          <h3>Please list all Pulmonary Diseases you have been diagnosed with:</h3>
          <CheckboxGroup options={pulmonaryOptions} selected={formData.pulmonaryDiseases} onChange={(e) => handleChange(e, 'pulmonaryDiseases')} />
          <br />

          <h3>Please list all Metabolic Diseases you have been diagnosed with:</h3>
          <CheckboxGroup options={metabolicOptions} selected={formData.metabolicDiseases} onChange={(e) => handleChange(e, 'metabolicDiseases')} />
          <br />

          {/* Section 3: Risk Factors */}
          <h2>Section 3: Risk Factors</h2>
          <RadioGroup question="Do you currently smoke or have quit smoking within the previous 6 months?" name="smokingRisk" selected={formData.riskFactors.smokingRisk} onChange={(e) => handleRadioChange(e, 'smokingRisk')} />
          <RadioGroup question="Did you exercise LESS THAN a total of 90 minutes last week?" name="exerciseRisk" selected={formData.riskFactors.exerciseRisk} onChange={(e) => handleRadioChange(e, 'exerciseRisk')} />
          <RadioGroup question="Is your Body Mass Index (BMI) over 30?" name="bmiRisk" selected={formData.riskFactors.bmiRisk} onChange={(e) => handleRadioChange(e, 'bmiRisk')} />
          <RadioGroup question="Is your blood pressure higher than 140/90 mmHg or are you on High Blood Pressure medication?" name="bloodPressureRisk" selected={formData.riskFactors.bloodPressureRisk} onChange={(e) => handleRadioChange(e, 'bloodPressureRisk')} />
          <RadioGroup question="Are you on Cholesterol medication or have high cholesterol?" name="cholesterolRisk" selected={formData.riskFactors.cholesterolRisk} onChange={(e) => handleRadioChange(e, 'cholesterolRisk')} />
          <br />

          {/* Section 4: Musculoskeletal History */}
          <h2>Section 4: Musculoskeletal History</h2>
          {joints.map((joint) => (
            <div key={joint} className="musculoskeletal-group">
              <label className="musculoskeletal-label">{joint}:</label>
              <label><input type="checkbox" onChange={(e) => handleMusculoskeletalChange(e, joint, 'injury')} /> Injury</label>
              <label><input type="checkbox" onChange={(e) => handleMusculoskeletalChange(e, joint, 'surgery')} /> Surgery</label>
              <label><input type="checkbox" onChange={(e) => handleMusculoskeletalChange(e, joint, 'physicalTherapy')} /> Physical Therapy</label>
              <br />
            </div>
          ))}
          <br />

          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default DataEntry;
