import React, { useEffect } from 'react';
import FMSForm from './FMSForm';

const FMSSection = ({ clientId, gender, age, onFmsLoad }) => {
  useEffect(() => {
    onFmsLoad();
  }, [onFmsLoad]);

  return (
    <div className="fms-section">
      
      <FMSForm clientId={clientId} gender={gender} age={age} onUpdate={onFmsLoad} />
    </div>
  );
};

export default FMSSection;
