import React, { useState } from 'react';

const ExerciseDatabase = ({ exercises, onDeleteExercise, onEditExercise }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({
    equipment: '',
    pattern: '',
    muscle: '',
    position: '', // Add position filter
  });

  // Filter exercises based on search term, dropdown selections, and muscle group
  const filteredExercises = exercises.filter((exercise) => {
    const matchesName = exercise.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesEquipment = filter.equipment === '' || exercise.equipment === filter.equipment;
    const matchesPattern = filter.pattern === '' || exercise.pattern === filter.pattern;
    const matchesMuscle =
      filter.muscle === '' || (Array.isArray(exercise.muscles) && exercise.muscles.includes(filter.muscle));
    const matchesPosition =
      filter.position === '' || (Array.isArray(exercise.positions) && exercise.positions.includes(filter.position));

    return matchesName && matchesEquipment && matchesPattern && matchesMuscle && matchesPosition;
  });

  // Handle filter changes for equipment, functional pattern, muscle group, and position
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  // Clear all filters
  const clearFilters = () => {
    setFilter({
      equipment: '',
      pattern: '',
      muscle: '',
      position: '',
    });
    setSearchTerm('');
  };

  return (
    <div>
      <h2>Exercise Database</h2>
      <div style={{ marginBottom: '20px' }}>
        {/* Search by Exercise Name */}
        <input
          type="text"
          placeholder="Search by Exercise Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: '10px' }}
        />

        {/* Filter by Equipment */}
        <select name="equipment" value={filter.equipment} onChange={handleFilterChange} style={{ marginRight: '10px' }}>
          <option value="">All Equipment</option>
          <option value="Barbell">Barbell</option>
          <option value="Dumbbell">Dumbbell</option>
          <option value="Cable">Cable</option>
          <option value="Bodyweight">Bodyweight</option>
        </select>

        {/* Filter by Functional Pattern */}
        <select name="pattern" value={filter.pattern} onChange={handleFilterChange} style={{ marginRight: '10px' }}>
          <option value="">All Patterns</option>
          <option value="Vertical Push">Vertical Push</option>
          <option value="Horizontal Push">Horizontal Push</option>
          <option value="Vertical Pull">Vertical Pull</option>
          <option value="Horizontal Pull">Horizontal Pull</option>
          <option value="Squat">Squat</option>
          <option value="Hinge">Hinge</option>
          <option value="Lunge">Lunge</option>
          <option value="Sagittal Core">Sagittal Core</option>
          <option value="Frontal Core">Frontal Core</option>
          <option value="Transverse Core">Transverse Core</option>
          <option value="Isolation">Isolation</option>
        </select>

        {/* Filter by Muscle Group */}
        <select name="muscle" value={filter.muscle} onChange={handleFilterChange} style={{ marginRight: '10px' }}>
          <option value="">All Muscles</option>
          <option value="Neck">Neck</option>
          <option value="Chest">Chest</option>
          <option value="Upper Back">Upper Back</option>
          <option value="Lower Back">Lower Back</option>
          <option value="Shoulders">Shoulders</option>
          <option value="Rotator Cuff">Rotator Cuff</option>
          <option value="Triceps">Triceps</option>
          <option value="Biceps">Biceps</option>
          <option value="Forearm">Forearm</option>
          <option value="Abdominals">Abdominals</option>
          <option value="Obliques">Obliques</option>
          <option value="Deep Core">Deep Core</option>
          <option value="Quadriceps">Quadriceps</option>
          <option value="Hamstrings">Hamstrings</option>
          <option value="Glutes">Glutes</option>
          <option value="Hip Adductors">Hip Adductors</option>
          <option value="Hip Abductors">Hip Abductors</option>
          <option value="Calves">Calves</option>
        </select>

        {/* Filter by Position */}
        <select name="position" value={filter.position} onChange={handleFilterChange}>
          <option value="">All Positions</option>
          <option value="Standing">Standing</option>
          <option value="Seated">Seated</option>
          <option value="Tall Kneeling">Tall Kneeling</option>
          <option value="Half Kneeling">Half Kneeling</option>
          <option value="Quadruped">Quadruped</option>
          <option value="Split Stance">Split Stance</option>
          <option value="Step Stance">Step Stance</option>
          <option value="Prone">Prone</option>
          <option value="Supine">Supine</option>
          <option value="Hanging">Hanging</option>
          <option value="Hinged">Hinged</option>
          <option value="Side Lying">Side Lying</option>
        </select>

        {/* Clear Filters Button */}
        <button onClick={clearFilters} style={{ marginLeft: '10px' }}>Clear Filters</button>
      </div>

      {/* Render the list of filtered exercises */}
      <div>
        {filteredExercises.length > 0 ? (
          filteredExercises.map((exercise, index) => (
            <div key={index} style={{ border: '1px solid #ddd', padding: '15px', marginBottom: '10px' }}>
              <h3>{exercise.name}</h3>
              <p><strong>Equipment:</strong> {exercise.equipment}</p>
              <p><strong>Pattern:</strong> {exercise.pattern}</p>
              <p><strong>Muscles Involved:</strong> {Array.isArray(exercise.muscles) ? exercise.muscles.join(', ') : 'No muscles specified'}</p>
              <p><strong>Positions:</strong> {Array.isArray(exercise.positions) ? exercise.positions.join(', ') : 'No positions specified'}</p>
              <p><strong>Instructions:</strong> {exercise.instructions}</p>
              {exercise.photo && (
                <div>
                  <strong>Photo Example:</strong>
                  <br />
                  <img src={exercise.photo} alt={exercise.name} width="200" />
                </div>
              )}
              {exercise.video && (
                <div>
                  <strong>Instructional Video:</strong>
                  <br />
                  <video src={exercise.video} controls width="300"></video>
                </div>
              )}

              {/* Edit and Delete buttons */}
              <button onClick={() => onEditExercise(exercise)} style={{ marginRight: '10px' }}>Edit</button>
              <button onClick={() => onDeleteExercise(exercise.id)} style={{ color: 'red' }}>Delete</button>
            </div>
          ))
        ) : (
          <p>No exercises found. Try a different search or filter.</p>
        )}
      </div>
    </div>
  );
};

export default ExerciseDatabase;
