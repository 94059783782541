import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { collection, getDocs, addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebase';

// Fetch exercises from Firebase
const fetchExercises = async () => {
  try {
    const exercisesRef = collection(db, 'exercises');
    const querySnapshot = await getDocs(exercisesRef);
    const exercises = [];
    querySnapshot.forEach((doc) => {
      exercises.push({
        id: doc.id, 
        name: doc.data().name, 
        equipment: doc.data().equipment
      });
    });
    return exercises;
  } catch (error) {
    console.error('Error fetching exercises:', error);
    return [];
  }
};

const getMesocycleOptions = (macrocycleDuration) => {
  const optionsMap = {
    1: [1],
    2: [1, 2],
    4: [1, 2],
    6: [1, 2, 3],
    8: [1, 2, 4],
    12: [1, 2, 3, 4],
  };

  return optionsMap[macrocycleDuration] || [1]; // Default to 1 if no valid duration is provided
};

const ProgramCreator = ({ onProgramCreated, program }) => {
  const [programName, setProgramName] = useState(program?.programName || '');
  const [programType, setProgramType] = useState(program?.programType || '');
  const [macrocycleDuration, setMacrocycleDuration] = useState(program?.macrocycleDuration || '');
  const [mesocycles, setMesocycles] = useState(program?.mesocycles || '');
  const [mesocycleOptions, setMesocycleOptions] = useState([1]);
  const [trainingFrequency, setTrainingFrequency] = useState(program?.trainingFrequency || '');
  const [exercisesPerWorkout, setExercisesPerWorkout] = useState(program?.exercisesPerWorkout || '');
  const [trainingGoal, setTrainingGoal] = useState(program?.trainingGoal || '');
  const [experienceLevel, setExperienceLevel] = useState(program?.experienceLevel || '');
  const [workouts, setWorkouts] = useState([]);
  const [exerciseDatabase, setExerciseDatabase] = useState([]);
  const [expandedView, setExpandedView] = useState(false); // A single toggle for expanded view
  const [isEditing, setIsEditing] = useState(!!program); // Check if we're editing

  const trainingGoals = ['Hypertrophy', 'Strength', 'Endurance', 'Functionality'];
  const experienceLevels = ['Beginner', 'Intermediate', 'Advanced'];
  const programTypes = ['Static', 'Dynamic'];

  useEffect(() => {
    const fetchExercisesData = async () => {
      const exercises = await fetchExercises();
      setExerciseDatabase(exercises);
    };
    fetchExercisesData();
  }, []);

  useEffect(() => {
    if (expandedView) {
      generateWorkouts(macrocycleDuration, mesocycles);
    }
  }, [mesocycles, trainingFrequency, exercisesPerWorkout, macrocycleDuration, expandedView]);

  useEffect(() => {
    if (program) {
      setProgramName(program.programName);
      setProgramType(program.programType);
      setMacrocycleDuration(program.macrocycleDuration);
      setMesocycles(program.mesocycles);
      setTrainingFrequency(program.trainingFrequency);
      setExercisesPerWorkout(program.exercisesPerWorkout);
      setTrainingGoal(program.trainingGoal);
      setExperienceLevel(program.experienceLevel);
      setIsEditing(true);
    }
  }, [program]);

  const generateWorkouts = (macrocycleDuration, mesocycles) => {
    const mesocycleWeeks = macrocycleDuration / mesocycles;
    const totalUniqueWorkouts = mesocycles * trainingFrequency;
  
    let workouts = [];
  
    // Create unique workouts across mesocycles
    for (let workoutIndex = 0; workoutIndex < totalUniqueWorkouts; workoutIndex++) {
      let exercises = [];
      for (let exerciseIdx = 0; exerciseIdx < exercisesPerWorkout; exerciseIdx++) {
        exercises.push({
          exerciseId: '',
          exerciseName: '',
          equipment: '',
          sets: Array(mesocycleWeeks).fill(0),
          reps: Array(mesocycleWeeks).fill(0),
          exerciseOrder: exerciseIdx + 1, // Add exerciseOrder here
        });
      }
  
      workouts.push({
        workoutNumber: workoutIndex + 1,
        mesocycle: Math.ceil((workoutIndex + 1) / trainingFrequency),
        exercises,
      });
    }
  
    setWorkouts(workouts);
  };

  const handleExerciseSelection = (workoutIdx, exerciseIdx, selectedExercise) => {
    const updatedWorkouts = [...workouts];
    const exerciseDetails = exerciseDatabase.find((ex) => ex.name === selectedExercise.value);
  
    updatedWorkouts[workoutIdx].exercises[exerciseIdx] = {
      exerciseId: exerciseDetails.id,
      exerciseName: exerciseDetails.name,
      equipment: exerciseDetails.equipment,
      sets: updatedWorkouts[workoutIdx].exercises[exerciseIdx].sets,
      reps: updatedWorkouts[workoutIdx].exercises[exerciseIdx].reps,
      exerciseOrder: exerciseIdx + 1, // Ensure exerciseOrder is preserved
    };
    setWorkouts(updatedWorkouts);
  };

  const handleSetsRepsChange = (workoutIdx, exerciseIdx, weekIdx, type, value) => {
    const updatedWorkouts = [...workouts];
    if (type === 'sets') {
      updatedWorkouts[workoutIdx].exercises[exerciseIdx].sets[weekIdx] = Number(value);
    } else {
      updatedWorkouts[workoutIdx].exercises[exerciseIdx].reps[weekIdx] = Number(value);
    }
    setWorkouts(updatedWorkouts);
  };

  const renderStaticWorkoutTable = (workout, mesocycleWeeks) => {
    return (
      <table key={workout.workoutNumber}>
        <thead>
          <tr>
            <th>Order</th>
            <th>Exercise</th>
            {Array.from({ length: mesocycleWeeks }).map((_, weekIdx) => (
              <React.Fragment key={weekIdx}>
                <th>Week {weekIdx + 1 + (workout.mesocycle - 1) * mesocycleWeeks} Sets</th>
                <th>Reps</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {workout.exercises.map((exercise, exerciseIdx) => (
            <tr key={exerciseIdx}>
              <td>{exercise.exerciseOrder}</td>
              <td>
                <Select
                  value={
                    exerciseDatabase.find((ex) => ex.name === exercise.exerciseName)
                      ? { value: exercise.exerciseName, label: `${exercise.exerciseName} (${exercise.equipment})` }
                      : null
                  }
                  options={exerciseDatabase.map((ex) => ({
                    value: ex.name,
                    label: `${ex.name} (${ex.equipment})`,
                  }))}
                  onChange={(selectedOption) =>
                    handleExerciseSelection(workout.workoutNumber - 1, exerciseIdx, selectedOption)
                  }
                />
              </td>
              {Array.from({ length: mesocycleWeeks }).map((_, weekIdx) => (
                <React.Fragment key={weekIdx}>
                  <td>
                    <input
                      type="number"
                      placeholder="Sets"
                      value={exercise.sets[weekIdx] || ''}
                      onChange={(e) =>
                        handleSetsRepsChange(workout.workoutNumber - 1, exerciseIdx, weekIdx, 'sets', e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="Reps"
                      value={exercise.reps[weekIdx] || ''}
                      onChange={(e) =>
                        handleSetsRepsChange(workout.workoutNumber - 1, exerciseIdx, weekIdx, 'reps', e.target.value)
                      }
                    />
                  </td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderProgram = () => {
    const mesocycleWeeks = macrocycleDuration / mesocycles;

    return workouts.map((workout) => (
      <div key={workout.workoutNumber}>
        <h4>
          Mesocycle {workout.mesocycle} - Workout {workout.workoutNumber}
        </h4>
        {renderStaticWorkoutTable(workout, mesocycleWeeks)}
      </div>
    ));
  };

  const handleSubmitProgram = async () => {
    if (!window.confirm('Is all program information correct before submitting?')) {
      return;
    }
  
    const programData = {
      programName,
      macrocycleDuration,
      mesocycles,
      trainingFrequency,
      exercisesPerWorkout,
      trainingGoal,
      experienceLevel,
      programType,
    };
  
    try {
      // Create or update the program in Firestore
      const programDocRef = isEditing 
        ? doc(db, 'programs', program.id)
        : await addDoc(collection(db, 'programs'), programData);
  
      // Loop through workouts and exercises to save them
      for (const workout of workouts) {
        const workoutDocRef = await addDoc(collection(programDocRef, 'workouts'), {
          workoutNumber: workout.workoutNumber,
          mesocycle: workout.mesocycle,
        });
  
        for (const exercise of workout.exercises) {
          await addDoc(collection(workoutDocRef, 'exercises'), {
            exerciseId: exercise.exerciseId,
            exerciseName: exercise.exerciseName,
            equipment: exercise.equipment,
            sets: exercise.sets,
            reps: exercise.reps,
            exerciseOrder: exercise.exerciseOrder,
          });
        }
      }
  
      alert('Program created successfully!');
  
      // Reset the form and collapse the expanded view
      if (onProgramCreated) onProgramCreated(programData);
  
      // Reset all form fields
      setProgramName('');
      setProgramType('');
      setMacrocycleDuration('');
      setMesocycles('');
      setTrainingFrequency('');
      setExercisesPerWorkout('');
      setTrainingGoal('');
      setExperienceLevel('');
      setExpandedView(false); // Collapse the expanded view
      setIsEditing(false);
      setWorkouts([]); // Clear workouts
      setExerciseDatabase([]); // Clear exercise database if needed
  
    } catch (error) {
      console.error('Error saving program:', error);
      alert('Failed to save the program. Please try again.');
    }
  };

  // Check if all fields are filled out
  const isFormComplete = () =>
    programName && programType && macrocycleDuration && mesocycles && trainingFrequency && exercisesPerWorkout && trainingGoal && experienceLevel;

  // Update mesocycle options based on program duration
  useEffect(() => {
    setMesocycleOptions(getMesocycleOptions(macrocycleDuration));
  }, [macrocycleDuration]);

  return (
    <div>
      <h2>{isEditing ? 'Edit Program' : 'Create New Program'}</h2>
      <div className="form-group">
      
        <label>Program Name:</label>
        <input
          type="text"
          value={programName}
          onChange={(e) => setProgramName(e.target.value)}
        />
      </div>

      <div>
        <label>Program Type: </label>
        <select value={programType} onChange={(e) => setProgramType(e.target.value)}>
          <option value="" disabled>
            Select Program Type
          </option>
          {programTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Macrocycle Duration: </label>
        <select value={macrocycleDuration} onChange={(e) => setMacrocycleDuration(Number(e.target.value))}>
          <option value="">Select Duration</option>
          {[1, 2, 4, 6, 8, 12].map((duration) => (
            <option key={duration} value={duration}>
              {duration} Weeks
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Mesocycles: </label>
        <select value={mesocycles} onChange={(e) => setMesocycles(Number(e.target.value))}>
          <option value="">Select Mesocycles</option>
          {mesocycleOptions.map((cycle) => (
            <option key={cycle} value={cycle}>
              {cycle} Mesocycles
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Training Frequency: </label>
        <select value={trainingFrequency} onChange={(e) => setTrainingFrequency(Number(e.target.value))}>
          <option value="" disabled>
            Select Workouts/Week
          </option>
          {[2, 3, 4, 5, 6].map((freq) => (
            <option key={freq} value={freq}>
              {freq} Workouts/Week
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Exercises Per Workout: </label>
        <select value={exercisesPerWorkout} onChange={(e) => setExercisesPerWorkout(Number(e.target.value))}>
          <option value="" disabled>
            Select Exercises/Workout
          </option>
          {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
            <option key={count} value={count}>
              {count} Exercises
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Training Goal: </label>
        <select value={trainingGoal} onChange={(e) => setTrainingGoal(e.target.value)}>
          <option value="" disabled>
            Select Training Goal
          </option>
          {trainingGoals.map((goal) => (
            <option key={goal} value={goal}>
              {goal}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label>Experience Level: </label>
        <select value={experienceLevel} onChange={(e) => setExperienceLevel(e.target.value)}>
          <option value="" disabled>
            Select Experience Level
          </option>
          {experienceLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </select>
      </div>

      <button
        onClick={() => {
          if (isFormComplete()) {
            setExpandedView(true);
          } else {
            alert('Please complete all the fields before designing the program.');
          }
        }}
      >
        {isEditing ? 'Edit Program' : 'Design Program'}
      </button>

      {expandedView && (
        <>
          <div>{renderProgram()}</div>
          <button onClick={handleSubmitProgram}>
            {isEditing ? 'Update Program' : 'Create Program'}
          </button>
          <button onClick={() => setExpandedView(false)}>Close Program View</button>
        </>
      )}
    </div>
  );
};

export default ProgramCreator;
