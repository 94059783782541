import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logOut } from './firebase'; // Ensure this import is correct

import ProtectedRoute from './ProtectedRoute';
import Login from './Login';
import Signup from './Signup';
import ClientDashboard from './ClientDashboard';
import GroupTrainingGrid from './GroupTrainingGrid';
import ExerciseManager from './ExerciseManager';
import ProgramManager from './ProgramManager';
import ClientProfile from './ClientProfile';
import DataEntry from './DataEntry';
import ProgramDatabase from './ProgramDatabase';
import ProgramDetail from './ProgramDetail';
import LandingPage from './LandingPage';

function App() {
  // Use the useAuthState hook to get the authenticated user
  const [user, loading] = useAuthState(auth);

  const handleLogout = async () => {
    await logOut();
    alert('You have been logged out');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <nav style={styles.navbar}>
          <a href="/dashboard" style={styles.link}>Client Dashboard</a>
          {user ? (
            <>
              <a href="/exercise-manager" style={styles.link}>Exercise Manager</a>
              <a href="/program-manager" style={styles.link}>Program Manager</a>
              <a href="/training" style={styles.link}>Training</a>
              <button onClick={handleLogout} style={styles.logoutButton}>Log Out</button>
            </>
          ) : (
            <>
              <a href="/login" style={styles.link}>Login</a>
              <a href="/signup" style={styles.link}>Sign Up</a>
            </>
          )}
        </nav>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<ProtectedRoute><ClientDashboard /></ProtectedRoute>} />
          <Route path="/training" element={<ProtectedRoute><GroupTrainingGrid /></ProtectedRoute>} />
          <Route path="/exercise-manager" element={<ProtectedRoute><ExerciseManager /></ProtectedRoute>} />
          <Route path="/program-manager" element={<ProtectedRoute><ProgramManager /></ProtectedRoute>} />
          <Route path="/client/:id" element={<ProtectedRoute><ClientProfile /></ProtectedRoute>} />
          <Route path="/intake-form" element={<ProtectedRoute><DataEntry /></ProtectedRoute>} />
          <Route path="/program-database" element={<ProtectedRoute><ProgramDatabase /></ProtectedRoute>} />
          <Route path="/program-detail/:id" element={<ProtectedRoute><ProgramDetail /></ProtectedRoute>} />
        </Routes>
      </div>
    </Router>
  );
}

// Styles
const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#282c34',
    padding: '15px',
  },
  link: {
    margin: '0 15px',
    textDecoration: 'none',
    color: 'white',
    fontSize: '18px',
  },
  logoutButton: {
    marginLeft: '15px',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
  },
};

export default App;