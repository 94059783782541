import React, { useState } from 'react';
import { signIn } from './firebase'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      alert('Login successful!');
      navigate('/dashboard');
    } catch (err) {
      console.error('Error logging in:', err.message);
      setError('Invalid email or password.');
    }
  };

  return (
    <div className="login-container">
      {/* Logo */}
      <img src="/MPlogo_for_dark-removebg.png" alt="MyoPatterns Logo" className="login-logo" />

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Login Form */}
      <form onSubmit={handleLogin} className="login-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="login-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="login-input"
        />
        <button type="submit" className="login-button">Log In</button>
      </form>
    </div>
  );
};

export default Login;