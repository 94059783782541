import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const WorkoutHistory = ({ clientId }) => {
  const [workoutHistory, setWorkoutHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newWorkout, setNewWorkout] = useState({
    date: '',
    name: '',
    details: '',
    clientId: clientId,
  });

  const loadWorkoutHistory = async () => {
    try {
      const q = query(collection(db, 'workouts'), where('clientId', '==', clientId));
      const querySnapshot = await getDocs(q);

      const workouts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setWorkoutHistory(workouts);
    } catch (error) {
      console.error('Error loading workout history:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewWorkoutSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'workouts'), newWorkout);
      setNewWorkout({ date: '', name: '', details: '', clientId: clientId });
      loadWorkoutHistory(); // Refresh the workout history
    } catch (error) {
      console.error('Error logging new workout:', error);
    }
  };

  useEffect(() => {
    loadWorkoutHistory();
  }, [clientId]);

  if (loading) {
    return <p>Loading workout history...</p>;
  }

  if (workoutHistory.length === 0) {
    return <p>No workout history found.</p>;
  }

  return (
    <div>
      <h2>Workout History</h2>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Workout Name</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {workoutHistory.map((workout) => (
            <tr key={workout.id}>
              <td>{workout.date}</td>
              <td>{workout.name}</td>
              <td>{workout.details}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* New Workout Form */}
      <h3>Log New Workout</h3>
      <form onSubmit={handleNewWorkoutSubmit}>
        <input
          type="date"
          value={newWorkout.date}
          onChange={(e) => setNewWorkout({ ...newWorkout, date: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Workout Name"
          value={newWorkout.name}
          onChange={(e) => setNewWorkout({ ...newWorkout, name: e.target.value })}
          required
        />
        <textarea
          placeholder="Workout Details"
          value={newWorkout.details}
          onChange={(e) => setNewWorkout({ ...newWorkout, details: e.target.value })}
          required
        />
        <button type="submit">Log Workout</button>
      </form>
    </div>
  );
};

export default WorkoutHistory;
