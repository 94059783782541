import React, { useEffect, useState } from 'react';
import AddExerciseForm from './AddExerciseForm';
import ExerciseDatabase from './ExerciseDatabase';
import { collection, addDoc, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from './firebase'; // Import Firestore database instance

const ExerciseManager = () => {
  const [exercises, setExercises] = useState([]); // State to hold exercises
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [exerciseToEdit, setExerciseToEdit] = useState(null); // State to track the exercise being edited

  // Fetch exercises from Firestore on component mount
  useEffect(() => {
    const fetchExercises = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'exercises')); // Fetch exercises from Firestore
        const exerciseList = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Include document ID
          ...doc.data(), // Spread the document data
        }));
        setExercises(exerciseList); // Update state with fetched exercises
      } catch (e) {
        console.error('Error fetching exercises: ', e);
      }
      setLoading(false);
    };

    fetchExercises();
  }, []); // Empty dependency array to run only on mount

  // Function to add a new exercise to Firestore and update local state
  const addExerciseToDatabase = async (newExercise) => {
    try {
      const docRef = await addDoc(collection(db, 'exercises'), newExercise); // Add new exercise to Firestore
      setExercises([...exercises, { id: docRef.id, ...newExercise }]); // Update local state
      setSuccessMessage('Exercise added successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (e) {
      console.error('Error adding exercise: ', e);
      alert('Failed to add exercise. Please try again.');
    }
  };

  const deleteExercise = async (exerciseId) => {
    // Confirmation check before deletion
    const confirmDelete = window.confirm('Are you sure you want to delete this exercise? This action cannot be undone.');
  
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'exercises', exerciseId)); // Delete the exercise from Firestore
        setExercises(exercises.filter((exercise) => exercise.id !== exerciseId)); // Remove from local state
        setSuccessMessage('Exercise deleted successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
      } catch (e) {
        console.error('Error deleting exercise: ', e);
        alert('Failed to delete exercise. Please try again.');
      }
    }
  };
  

  // Function to start editing an exercise
  const startEditingExercise = (exercise) => {
    setExerciseToEdit(exercise); // Set the selected exercise as the one to edit
  };

  // Function to update an exercise in Firestore and local state
  const updateExercise = async (updatedExercise) => {
    try {
      const exerciseDoc = doc(db, 'exercises', updatedExercise.id);
      await updateDoc(exerciseDoc, updatedExercise); // Update in Firestore

      // Update local state
      setExercises(exercises.map((exercise) => (exercise.id === updatedExercise.id ? updatedExercise : exercise)));

      setExerciseToEdit(null); // Clear the editing state
      setSuccessMessage('Exercise updated successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (e) {
      console.error('Error updating exercise: ', e);
      alert('Failed to update exercise. Please try again.');
    }
  };

  return (
    <div style={{ padding: '20px', border: '2px solid green' }}>
      <h1>Exercise Manager</h1>
      <AddExerciseForm onAddExercise={addExerciseToDatabase} exerciseToEdit={exerciseToEdit} onUpdateExercise={updateExercise} />
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {loading ? (
        <p>Loading exercises...</p>
      ) : (
        <ExerciseDatabase exercises={exercises} onDeleteExercise={deleteExercise} onEditExercise={startEditingExercise} />
      )}
    </div>
  );
};

export default ExerciseManager;
