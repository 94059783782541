import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Firebase config
import './ClientExerciseRecord.css'; // Make sure the CSS file is linked

const ClientExerciseRecord = ({ clientId }) => {
  const [exerciseRecords, setExerciseRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch Exercise Records for the Client
  const fetchExerciseRecords = async () => {
    setLoading(true);
    const recordsRef = collection(db, `clients/${clientId}/ExerciseRecords`);
    const recordsSnap = await getDocs(recordsRef);
    const recordsData = recordsSnap.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setExerciseRecords(recordsData);
    setLoading(false);
  };

  useEffect(() => {
    if (clientId) {
      fetchExerciseRecords();
    }
  }, [clientId]);

  // Render Exercise Record Table
  const renderExerciseRecords = () => {
    if (exerciseRecords.length === 0) {
      return <p>No exercise records found.</p>;
    }

    return (
      <table className="exercise-record-table">
        <thead>
          <tr>
            <th>Exercise Name</th>
            <th>Equipment</th>
            <th>Estimated 1RM</th>
            <th>Max Weight Lifted</th>
            <th>Date</th>
            <th>Personal Record</th>
          </tr>
        </thead>
        <tbody>
          {exerciseRecords.map(record => (
            <tr key={record.id}>
              <td>{record.exerciseName}</td>
              <td>{record.equipment || 'N/A'}</td> {/* Added Equipment */}
              <td>{record.estimated1RM.toFixed(2)} lbs</td>
              <td>{record.personalRecord.maxWeightLifted} lbs</td>
              <td>{record.personalRecord.date}</td>
              <td>{record.personalRecord.maxWeightLifted} lbs for {record.reps || 'N/A'} reps</td> {/* Personal Record Display */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="client-exercise-record">
      <h2>Exercise Records</h2>
      {loading ? <p>Loading records...</p> : renderExerciseRecords()}
    </div>
  );
};

export default ClientExerciseRecord;
