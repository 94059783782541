import React, { useState, useEffect } from 'react';
import { doc, updateDoc, collection, addDoc, query, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from './firebase'; // Import your Firebase configuration
import './FMSForm.css'; // Ensure FMSForm.css is imported


const FMSForm = ({ clientId, onUpdate, gender, age }) => {
  // Function to get today's date in the format YYYY-MM-DD
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Initial State with all fields blank and today's date
const [fmsScores, setFmsScores] = useState({
  evaluationDate: getTodayDate(),
  
  // Lower Body Screens
  deepSquat: '',
  ankleMobilityLeft: '',
  ankleMobilityRight: '',
  hurdleStepLeft: '',
  hurdleStepRight: '',
  inlineLungeLeft: '',
  inlineLungeRight: '',
  
  // Shoulder and Arm Screens
  handSpan: '',
  shoulderExternalRotationLeft: '',
  shoulderExternalRotationRight: '',
  shoulderInternalRotationLeft: '',
  shoulderInternalRotationRight: '',
  shoulderInternalRotationScoreLeft: '',
  shoulderInternalRotationScoreRight: '',
  shoulderExternalRotationScoreLeft: '',
  shoulderExternalRotationScoreRight: '',
  shoulderMobilityLeft: '',
  shoulderMobilityRight: '',
  shoulderImpingementClearingTestLeft: '',
  shoulderImpingementClearingTestRight: '',
  shoulderHorizontalAdductionClearingTestLeft: '',
  shoulderHorizontalAdductionClearingTestRight: '',
  
  // Hip Mobility Screens
  hipInternalRotationLeft: '',
  hipInternalRotationRight: '',
  hipExternalRotationLeft: '',
  hipExternalRotationRight: '',
  hipInternalRotationScoreLeft: '',
  hipInternalRotationScoreRight: '',
  hipExternalRotationScoreLeft: '',
  hipExternalRotationScoreRight: '',
  hipExtensionLeft: '',
  hipExtensionRight: '',
  
  // Core and Spine Screens
  toeTouch: '',
  trunkStabilityPushUp: '',
  spinalExtensionClearingTest: '',
  spinalFlexionClearingTest: '',
  rotaryStabilityLeft: '',
  rotaryStabilityRight: '',
  rotaryStabilityScoreLeft: '',
  rotaryStabilityScoreRight: '',

  // Wrist and Grip Screens
  wristExtensionLeft: '',
  wristExtensionRight: '',
  handGripStrengthLeft: '',
  handGripStrengthRight: '',
  leftGripStrengthScore: '', // Grip strength calculated score (left)
  rightGripStrengthScore: '', // Grip strength calculated score (right)

  // Calculated or Functional Scores
  shoulderInternalRotationFunctionalScore: '', // Lowest score between left and right internal rotation
  shoulderExternalRotationFunctionalScore: '', // Lowest score between left and right external rotation
  hipInternalRotationFunctionalScore: '', // Lowest score between left and right internal hip rotation
  hipExternalRotationFunctionalScore: '', // Lowest score between left and right external hip rotation
  rotaryStabilityFunctionalScore: '', // Lowest score between left and right rotary stability
});


  const [successMessage, setSuccessMessage] = useState('');

  const loadPreviousScores = async () => {
    try {
      const evaluationsRef = collection(db, `clients/${clientId}/fmsEvaluations`);
      const q = query(evaluationsRef, orderBy('evaluationDate', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const latestData = querySnapshot.docs[0].data();
        setFmsScores((prevData) => {
          const updatedScores = { ...prevData, ...latestData };
  
          const handSpan = parseFloat(updatedScores.handSpan || 0);
  
          // Shoulder internal and external rotation values
          const shoulderInternalLeft = parseFloat(updatedScores.shoulderInternalRotationLeft || 0);
          const shoulderExternalLeft = parseFloat(updatedScores.shoulderExternalRotationLeft || 0);
          const shoulderInternalRight = parseFloat(updatedScores.shoulderInternalRotationRight || 0);
          const shoulderExternalRight = parseFloat(updatedScores.shoulderExternalRotationRight || 0);
  
          // Debugging for values to ensure correctness
          console.log('Hand Span:', handSpan);
          console.log('Shoulder Internal Left:', shoulderInternalLeft, 'Shoulder External Left:', shoulderExternalLeft);
          console.log('Shoulder Internal Right:', shoulderInternalRight, 'Shoulder External Right:', shoulderExternalRight);
  
          // Recalculate Shoulder Mobility with NaN checks
          const shoulderMobilityLeft = (!isNaN(shoulderInternalLeft) && !isNaN(shoulderExternalLeft) && handSpan > 0)
            ? (shoulderInternalLeft + shoulderExternalLeft <= handSpan
              ? 3
              : (shoulderInternalLeft + shoulderExternalLeft <= handSpan * 1.5
                ? 2
                : 1))
            : '';
  
          const shoulderMobilityRight = (!isNaN(shoulderInternalRight) && !isNaN(shoulderExternalRight) && handSpan > 0)
            ? (shoulderInternalRight + shoulderExternalRight <= handSpan
              ? 3
              : (shoulderInternalRight + shoulderExternalRight <= handSpan * 1.5
                ? 2
                : 1))
            : '';
  
          // Debugging calculated mobility values
          console.log('Calculated Shoulder Mobility Left:', shoulderMobilityLeft);
          console.log('Calculated Shoulder Mobility Right:', shoulderMobilityRight);
  
          return {
            ...updatedScores,
            shoulderMobilityLeft: shoulderInternalLeft && shoulderExternalLeft ? shoulderMobilityLeft : '',
            shoulderMobilityRight: shoulderInternalRight && shoulderExternalRight ? shoulderMobilityRight : '',
          };
        });
  
        setSuccessMessage('Previous FMS scores loaded successfully!');
      } else {
        setSuccessMessage('No previous scores found.');
      }
    } catch (error) {
      console.error('Error loading previous FMS scores: ', error);
      setSuccessMessage('Failed to load previous scores.');
    }
  };
  
  


// Calculate Functional Score for Unilateral Tests
const calculateFunctionalScore = (leftScore, rightScore) => {
  if (leftScore === '' || rightScore === '' || leftScore === undefined || rightScore === undefined) return '—';
  return Math.min(leftScore, rightScore);
};

// Updated Hip Rotation Scoring Logic Function
const calculateHipRotationScore = (rotationValue) => {
  const rotation = parseFloat(rotationValue);
  if (isNaN(rotation)) return ''; // If invalid, return empty

  if (rotation >= 45) return 3; // Optimal (45 degrees or more)
  if (rotation >= 30) return 2; // Sufficient (30-44 degrees)
  if (rotation > 0) return 1;   // Insufficient (1-29 degrees)
  return 0; // Pain or no movement (0 degrees)
};

const getGripStrengthScore = (gender, age, gripStrength) => {
    if (gripStrength === '') return '—';
    const strength = parseFloat(gripStrength);
    const ranges = {
      Male: {
        '12-19': { high: 122.1, low: 42.7 },
        '20-29': { high: 126.5, low: 81 },
        '30-39': { high: 122.7, low: 79.2 },
        '40-49': { high: 119.9, low: 78.1 },
        '50-59': { high: 106.7, low: 72.4 },
        '60-69': { high: 96.8, low: 66.4 },
        '70+': { high: 77.2, low: 46.9 },
      },
      Female: {
        '12-19': { high: 68.2, low: 32.1 },
        '20-29': { high: 91, low: 47.3 },
        '30-39': { high: 75, low: 47.3 },
        '40-49': { high: 71.3, low: 41.5 },
        '50-59': { high: 69.3, low: 39.8 },
        '60-69': { high: 59.8, low: 37.84 },
        '70+': { high: 53.9, low: 32.3 },
      },
    };
    let ageGroup = '70+';
    if (age >= 12 && age < 20) ageGroup = '12-19';
    else if (age >= 20 && age < 30) ageGroup = '20-29';
    else if (age >= 30 && age < 40) ageGroup = '30-39';
    else if (age >= 40 && age < 50) ageGroup = '40-49';
    else if (age >= 50 && age < 60) ageGroup = '50-59';
    else if (age >= 60 && age < 70) ageGroup = '60-69';
    const { high, low } = ranges[gender][ageGroup];
    if (strength > high) return 3;
    if (strength >= low && strength <= high) return 2;
    return 1;
  };



  useEffect(() => {
    const leftGripScore = getGripStrengthScore(gender, age, fmsScores.handGripStrengthLeft);
    const rightGripScore = getGripStrengthScore(gender, age, fmsScores.handGripStrengthRight);
  
    // Calculate Hip Internal and External Rotation Scores
    const hipInternalRotationScoreLeft = calculateHipRotationScore(fmsScores.hipInternalRotationLeft);
    const hipInternalRotationScoreRight = calculateHipRotationScore(fmsScores.hipInternalRotationRight);
    const hipExternalRotationScoreLeft = calculateHipRotationScore(fmsScores.hipExternalRotationLeft);
    const hipExternalRotationScoreRight = calculateHipRotationScore(fmsScores.hipExternalRotationRight);
  
    // Calculate Shoulder Rotation Scores
    const handSpanValue = parseFloat(fmsScores.handSpan) || 0;

    // Function to calculate shoulder scores based on hand span, with improved validation
const calculateShoulderScore = (rotationValue) => {
  if (rotationValue === '' || rotationValue === undefined) return ''; // Check for empty or undefined values
  const rotation = parseFloat(rotationValue);
  if (isNaN(rotation) || handSpanValue === 0) return ''; // Check for invalid number or zero hand span
  if (rotation <= handSpanValue * 0.5) return 3; // ≤ 50% of hand span
  if (rotation <= handSpanValue * 0.75) return 2; // ≤ 75% of hand span
  return 1; // > 75% of hand span
};
  
    const shoulderInternalRotationScoreLeft = calculateShoulderScore(fmsScores.shoulderInternalRotationLeft);
    const shoulderInternalRotationScoreRight = calculateShoulderScore(fmsScores.shoulderInternalRotationRight);
    const shoulderExternalRotationScoreLeft = calculateShoulderScore(fmsScores.shoulderExternalRotationLeft);
    const shoulderExternalRotationScoreRight = calculateShoulderScore(fmsScores.shoulderExternalRotationRight);
  
    // Set the updated state with all calculated values
    setFmsScores((prevData) => ({
      ...prevData,
      leftGripStrengthScore: leftGripScore,
      rightGripStrengthScore: rightGripScore,
      hipInternalRotationScoreLeft,
      hipInternalRotationScoreRight,
      hipExternalRotationScoreLeft,
      hipExternalRotationScoreRight,
      shoulderInternalRotationScoreLeft,
      shoulderInternalRotationScoreRight,
      shoulderExternalRotationScoreLeft,
      shoulderExternalRotationScoreRight,
    }));
  }, [
    fmsScores.handGripStrengthLeft,
    fmsScores.handGripStrengthRight,
    fmsScores.hipInternalRotationLeft,
    fmsScores.hipInternalRotationRight,
    fmsScores.hipExternalRotationLeft,
    fmsScores.hipExternalRotationRight,
    fmsScores.shoulderInternalRotationLeft,
    fmsScores.shoulderInternalRotationRight,
    fmsScores.shoulderExternalRotationLeft,
    fmsScores.shoulderExternalRotationRight,
    fmsScores.handSpan,
    gender,
    age,
  ]);


  // Automatically load the previous scores when the component is mounted
  useEffect(() => {
    loadPreviousScores();
  }, [clientId]);
  
  const handleInputChange = (e, field) => {
    const { value } = e.target;

  // Log the input change to see what values are being entered
  console.log(`Field: ${field}, Value: ${value}`);

    setFmsScores((prevData) => {
      const updatedScores = { ...prevData, [field]: value };
  
      const handSpan = parseFloat(updatedScores.handSpan || 0);
          console.log('Hand Span:', handSpan); // Debugging the hand span value

      // Ensure that both internal and external rotations are valid numbers for both sides
      const shoulderInternalLeft = parseFloat(updatedScores.shoulderInternalRotationLeft || 0);
      const shoulderExternalLeft = parseFloat(updatedScores.shoulderExternalRotationLeft || 0);
      const shoulderInternalRight = parseFloat(updatedScores.shoulderInternalRotationRight || 0);
      const shoulderExternalRight = parseFloat(updatedScores.shoulderExternalRotationRight || 0);

// Debugging shoulder rotation values to make sure they are being processed correctly
console.log('Shoulder Internal Left:', shoulderInternalLeft);
console.log('Shoulder External Left:', shoulderExternalLeft);
console.log('Shoulder Internal Right:', shoulderInternalRight);
console.log('Shoulder External Right:', shoulderExternalRight);

      // Real-time Shoulder Mobility Calculation for Left and Right
      const shoulderMobilityLeft = (shoulderInternalLeft + shoulderExternalLeft <= handSpan)
        ? 3
        : (shoulderInternalLeft + shoulderExternalLeft <= handSpan * 1.5)
        ? 2
        : 1;
  
      const shoulderMobilityRight = (shoulderInternalRight + shoulderExternalRight <= handSpan)
        ? 3
        : (shoulderInternalRight + shoulderExternalRight <= handSpan * 1.5)
        ? 2
        : 1;
  
        console.log('Shoulder Mobility Left:', shoulderMobilityLeft); // Debugging calculated values
    console.log('Shoulder Mobility Right:', shoulderMobilityRight);

      // Update state only if both internal and external rotations are valid
      return {
        ...updatedScores,
        shoulderMobilityLeft: 
          updatedScores.shoulderInternalRotationLeft !== '' && updatedScores.shoulderExternalRotationLeft !== '' 
          ? shoulderMobilityLeft 
          : '',
        shoulderMobilityRight:
          updatedScores.shoulderInternalRotationRight !== '' && updatedScores.shoulderExternalRotationRight !== '' 
          ? shoulderMobilityRight 
          : '',
      };
    });
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Reference to the client's FMS Evaluations collection in Firestore
      const evaluationsRef = collection(db, `clients/${clientId}/fmsEvaluations`);
  
      // Ensure that hip internal and external rotation fields are captured correctly
      const newFmsData = {
        evaluationDate: fmsScores.evaluationDate,
  
        // Lower Body Screens
        deepSquat: fmsScores.deepSquat,
        ankleMobilityLeft: fmsScores.ankleMobilityLeft,
        ankleMobilityRight: fmsScores.ankleMobilityRight,
        hurdleStepLeft: fmsScores.hurdleStepLeft,
        hurdleStepRight: fmsScores.hurdleStepRight,
        inlineLungeLeft: fmsScores.inlineLungeLeft,
        inlineLungeRight: fmsScores.inlineLungeRight,
  
        // Hip Mobility Screens
        hipInternalRotationLeft: fmsScores.hipInternalRotationLeft || '',
        hipInternalRotationRight: fmsScores.hipInternalRotationRight || '',
        hipExternalRotationLeft: fmsScores.hipExternalRotationLeft || '',
        hipExternalRotationRight: fmsScores.hipExternalRotationRight || '',
        hipInternalRotationScoreLeft: fmsScores.hipInternalRotationScoreLeft || 0,
        hipInternalRotationScoreRight: fmsScores.hipInternalRotationScoreRight || 0,
        hipExternalRotationScoreLeft: fmsScores.hipExternalRotationScoreLeft || 0,
        hipExternalRotationScoreRight: fmsScores.hipExternalRotationScoreRight || 0,
        hipInternalRotationFunctionalScore: fmsScores.hipInternalRotationFunctionalScore || '—',
        hipExternalRotationFunctionalScore: fmsScores.hipExternalRotationFunctionalScore || '—',
        hipExtensionLeft: fmsScores.hipExtensionLeft || '',
        hipExtensionRight: fmsScores.hipExtensionRight || '',
  
        // Shoulder and Arm Screens
        handSpan: fmsScores.handSpan,
        shoulderExternalRotationLeft: fmsScores.shoulderExternalRotationLeft,
        shoulderExternalRotationRight: fmsScores.shoulderExternalRotationRight,
        shoulderInternalRotationLeft: fmsScores.shoulderInternalRotationLeft,
        shoulderInternalRotationRight: fmsScores.shoulderInternalRotationRight,
  
        // Shoulder Mobility Scores
        shoulderInternalRotationScoreLeft: fmsScores.shoulderInternalRotationScoreLeft || 0,
        shoulderInternalRotationScoreRight: fmsScores.shoulderInternalRotationScoreRight || 0,
        shoulderExternalRotationScoreLeft: fmsScores.shoulderExternalRotationScoreLeft || 0,
        shoulderExternalRotationScoreRight: fmsScores.shoulderExternalRotationScoreRight || 0,
        shoulderInternalRotationFunctionalScore: fmsScores.shoulderInternalRotationFunctionalScore || '—',
        shoulderExternalRotationFunctionalScore: fmsScores.shoulderExternalRotationFunctionalScore || '—',
  
        // Additional Shoulder Screens
        shoulderMobilityLeft: fmsScores.shoulderMobilityLeft || '',
        shoulderMobilityRight: fmsScores.shoulderMobilityRight || '',
        shoulderImpingementClearingTestLeft: fmsScores.shoulderImpingementClearingTestLeft || '—',
        shoulderImpingementClearingTestRight: fmsScores.shoulderImpingementClearingTestRight || '—',
        shoulderHorizontalAdductionClearingTestLeft: fmsScores.shoulderHorizontalAdductionClearingTestLeft || '—',
        shoulderHorizontalAdductionClearingTestRight: fmsScores.shoulderHorizontalAdductionClearingTestRight || '—',
        shoulderExternalStabilityLeft: fmsScores.shoulderExternalStabilityLeft || 0,
        shoulderExternalStabilityRight: fmsScores.shoulderExternalStabilityRight || 0,
        flexedShoulderExternalStabilityLeft: fmsScores.flexedShoulderExternalStabilityLeft || 0,
        flexedShoulderExternalStabilityRight: fmsScores.flexedShoulderExternalStabilityRight || 0,
  
        // Wrist and Grip Screens
        wristExtensionLeft: fmsScores.wristExtensionLeft || '',
        wristExtensionRight: fmsScores.wristExtensionRight || '',
        handGripStrengthLeft: fmsScores.handGripStrengthLeft,
        handGripStrengthRight: fmsScores.handGripStrengthRight,
  
        // Grip Strength Scores
        leftGripStrengthScore: fmsScores.leftGripStrengthScore,
        rightGripStrengthScore: fmsScores.rightGripStrengthScore,
  
        // Core and Spine Screens
        toeTouch: fmsScores.toeTouch,
        trunkStabilityPushUp: fmsScores.trunkStabilityPushUp,
        rotaryStabilityLeft: fmsScores.rotaryStabilityLeft,
        rotaryStabilityRight: fmsScores.rotaryStabilityRight,
        rotaryStabilityFunctionalScore: fmsScores.rotaryStabilityFunctionalScore,
  
        // Clearing Tests
        spinalExtensionClearingTest: fmsScores.spinalExtensionClearingTest,
        spinalFlexionClearingTest: fmsScores.spinalFlexionClearingTest,
  
        // Active Straight Leg Raise Screen
        activeStraightLegRaiseLeft: fmsScores.activeStraightLegRaiseLeft,
        activeStraightLegRaiseRight: fmsScores.activeStraightLegRaiseRight,
  
        // Calculated Functional Scores for Active Straight Leg Raise
        activeStraightLegRaiseFunctionalScore: fmsScores.activeStraightLegRaiseFunctionalScore || '',
      };
  
      // Adding a new document to the FMS evaluations sub-collection
      await addDoc(evaluationsRef, newFmsData); // Add new evaluation with all required fields
  
      // Show success message
      setSuccessMessage('FMS scores updated successfully!');
      onUpdate(); // Trigger parent component to refresh data
  
    } catch (error) {
      console.error('Error updating FMS scores: ', error);
    }
  };

return (
<div className="fms-form-container">
  <h2>Functional Movement Profile</h2>
    <form onSubmit={handleSubmit}>
    <div className="evaluation-date">
  <label>Evaluation Date:</label>
  <input type="date" value={fmsScores.evaluationDate} onChange={(e) => handleInputChange(e, 'evaluationDate')} />
</div>

      {/* FMS Scores Table */}
<table className="evaluation-table">
  <thead>
    <tr>
      <th className="screen-column">Evaluation Name</th>
      <th className="entry-column">Entry Field</th>
      <th className="prelim-score-column">Preliminary Score</th>
      <th className="func-score-column">Functional Score</th>
    </tr>
  </thead>
  <tbody>
  {/* Deep Squat (Bilateral) */}
  <tr>
    <td>Deep Squat Screen</td>
    <td>
      <select value={fmsScores.deepSquat} onChange={(e) => handleInputChange(e, 'deepSquat')}>
        <option value=""></option>
        <option value={0}>0 - Pain</option>
        <option value={1}>1 - Insufficient</option>
        <option value={2}>2 - Sufficient</option>
        <option value={3}>3 - Optimal</option>
      </select>
    </td>
    <td>{fmsScores.deepSquat || '—'}</td>
    <td>{fmsScores.deepSquat || '—'}</td>
  </tr>

  {/* Ankle Mobility (Unilateral) */}
<tr>
  <td>Ankle Mobility</td>
  <td>
    <div className="unilateral-entry">
      <select value={fmsScores.ankleMobilityLeft} onChange={(e) => handleInputChange(e, 'ankleMobilityLeft')}>
        <option value=""></option>
        <option value={0}>0 - Pain</option>
        <option value={1}>1 - Insufficient</option>
        <option value={2}>2 - Sufficient</option>
        <option value={3}>3 - Optimal</option>
      </select>
      <select value={fmsScores.ankleMobilityRight} onChange={(e) => handleInputChange(e, 'ankleMobilityRight')}>
        <option value=""></option>
        <option value={0}>0 - Pain</option>
        <option value={1}>1 - Insufficient</option>
        <option value={2}>2 - Sufficient</option>
        <option value={3}>3 - Optimal</option>
      </select>
    </div>
  </td>
  <td>
    <span>
      Left: {fmsScores.ankleMobilityLeft !== '' && fmsScores.ankleMobilityLeft !== undefined ? fmsScores.ankleMobilityLeft : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.ankleMobilityRight !== '' && fmsScores.ankleMobilityRight !== undefined ? fmsScores.ankleMobilityRight : '—'}
    </span>
  </td>
  <td>
    {fmsScores.ankleMobilityLeft !== '' && fmsScores.ankleMobilityRight !== '' &&
    fmsScores.ankleMobilityLeft !== undefined && fmsScores.ankleMobilityRight !== undefined
      ? calculateFunctionalScore(fmsScores.ankleMobilityLeft, fmsScores.ankleMobilityRight)
      : '—'}
  </td>
</tr>

<tr>
  <td>Hurdle Step Screen</td>
  <td>
    <div className="unilateral-entry">
      <select value={fmsScores.hurdleStepLeft} onChange={(e) => handleInputChange(e, 'hurdleStepLeft')}>
        <option value=""></option>
        <option value={0}>0 - Pain</option>
        <option value={1}>1 - Insufficient</option>
        <option value={2}>2 - Sufficient</option>
        <option value={3}>3 - Optimal</option>
      </select>
      <select value={fmsScores.hurdleStepRight} onChange={(e) => handleInputChange(e, 'hurdleStepRight')}>
        <option value=""></option>
        <option value={0}>0 - Pain</option>
        <option value={1}>1 - Insufficient</option>
        <option value={2}>2 - Sufficient</option>
        <option value={3}>3 - Optimal</option>
      </select>
    </div>
  </td>
  <td>
    <span>
      {fmsScores.hurdleStepLeft !== '' && fmsScores.hurdleStepLeft !== undefined
        ? `Left: ${fmsScores.hurdleStepLeft}`
        : 'Left: —'}
    </span>
    &nbsp;&nbsp;
    <span>
      {fmsScores.hurdleStepRight !== '' && fmsScores.hurdleStepRight !== undefined
        ? `Right: ${fmsScores.hurdleStepRight}`
        : 'Right: —'}
    </span>
  </td>
  <td>
    {fmsScores.hurdleStepLeft !== '' && fmsScores.hurdleStepRight !== '' &&
    fmsScores.hurdleStepLeft !== undefined && fmsScores.hurdleStepRight !== undefined
      ? calculateFunctionalScore(fmsScores.hurdleStepLeft, fmsScores.hurdleStepRight)
      : '—'}
  </td>
</tr>


  {/* In-Line Lunge (Unilateral) */}
<tr>
  <td>In-Line Lunge Screen</td>
  <td>
    <div className="unilateral-entry">
      <select value={fmsScores.inlineLungeLeft} onChange={(e) => handleInputChange(e, 'inlineLungeLeft')}>
        <option value=""></option>
        <option value={0}>0 - Pain</option>
        <option value={1}>1 - Insufficient</option>
        <option value={2}>2 - Sufficient</option>
        <option value={3}>3 - Optimal</option>
      </select>
      <select value={fmsScores.inlineLungeRight} onChange={(e) => handleInputChange(e, 'inlineLungeRight')}>
        <option value=""></option>
        <option value={0}>0 - Pain</option>
        <option value={1}>1 - Insufficient</option>
        <option value={2}>2 - Sufficient</option>
        <option value={3}>3 - Optimal</option>
      </select>
    </div>
  </td>
  <td>
    <span>
      Left: {fmsScores.inlineLungeLeft !== '' && fmsScores.inlineLungeLeft !== undefined ? fmsScores.inlineLungeLeft : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.inlineLungeRight !== '' && fmsScores.inlineLungeRight !== undefined ? fmsScores.inlineLungeRight : '—'}
    </span>
  </td>
  <td>
    {fmsScores.inlineLungeLeft !== '' && fmsScores.inlineLungeRight !== '' &&
    fmsScores.inlineLungeLeft !== undefined && fmsScores.inlineLungeRight !== undefined
      ? calculateFunctionalScore(fmsScores.inlineLungeLeft, fmsScores.inlineLungeRight)
      : '—'}
  </td>
</tr>


  {/* Hand Span Measurement (Bilateral) */}
  <tr>
    <td>Hand Span Measurement</td>
    <td>
      <select value={fmsScores.handSpan} onChange={(e) => handleInputChange(e, 'handSpan')}>
        <option value=""></option>
        {Array.from({ length: 21 }, (_, i) => 10 + i).map((val) => (
          <option key={val} value={val}>
            {val} cm
          </option>
        ))}
      </select>
    </td>
    <td>{fmsScores.handSpan || '—'}</td>
    <td>{fmsScores.handSpan || '—'}</td>
  </tr>

  <tr>
  <td>Shoulder Internal Rotation Mobility</td>
  <td>
    <div className="unilateral-entry">
      <select
        value={fmsScores.shoulderInternalRotationLeft}
        onChange={(e) => handleInputChange(e, 'shoulderInternalRotationLeft')}
      >
        <option value=""></option>
        {Array.from({ length: 41 }, (_, i) => i).map((value) => (
          <option key={value} value={value}>
            {value} cm
          </option>
        ))}
      </select>
      <select
        value={fmsScores.shoulderInternalRotationRight}
        onChange={(e) => handleInputChange(e, 'shoulderInternalRotationRight')}
      >
        <option value=""></option>
        {Array.from({ length: 41 }, (_, i) => i).map((value) => (
          <option key={value} value={value}>
            {value} cm
          </option>
        ))}
      </select>
    </div>
  </td>
  <td>
    <span>
      {fmsScores.shoulderInternalRotationScoreLeft !== '' && fmsScores.shoulderInternalRotationScoreLeft !== undefined
        ? `Left: ${fmsScores.shoulderInternalRotationScoreLeft}`
        : 'Left: —'}
    </span>
    &nbsp;&nbsp;
    <span>
      {fmsScores.shoulderInternalRotationScoreRight !== '' && fmsScores.shoulderInternalRotationScoreRight !== undefined
        ? `Right: ${fmsScores.shoulderInternalRotationScoreRight}`
        : 'Right: —'}
    </span>
  </td>
  <td>
    {fmsScores.shoulderInternalRotationScoreLeft !== '' && fmsScores.shoulderInternalRotationScoreRight !== '' &&
    fmsScores.shoulderInternalRotationScoreLeft !== undefined && fmsScores.shoulderInternalRotationScoreRight !== undefined
      ? calculateFunctionalScore(fmsScores.shoulderInternalRotationScoreLeft, fmsScores.shoulderInternalRotationScoreRight)
      : '—'}
  </td>
</tr>


<tr>
  <td>Shoulder External Rotation Mobility</td>
  <td>
    <div className="unilateral-entry">
      <select
        value={fmsScores.shoulderExternalRotationLeft}
        onChange={(e) => handleInputChange(e, 'shoulderExternalRotationLeft')}
      >
        <option value=""></option>
        {Array.from({ length: 41 }, (_, i) => i).map((value) => (
          <option key={value} value={value}>
            {value} cm
          </option>
        ))}
      </select>
      <select
        value={fmsScores.shoulderExternalRotationRight}
        onChange={(e) => handleInputChange(e, 'shoulderExternalRotationRight')}
      >
        <option value=""></option>
        {Array.from({ length: 41 }, (_, i) => i).map((value) => (
          <option key={value} value={value}>
            {value} cm
          </option>
        ))}
      </select>
    </div>
  </td>
  <td>
    <span>
      {fmsScores.shoulderExternalRotationScoreLeft !== '' && fmsScores.shoulderExternalRotationScoreLeft !== undefined
        ? `Left: ${fmsScores.shoulderExternalRotationScoreLeft}`
        : 'Left: —'}
    </span>
    &nbsp;&nbsp;
    <span>
      {fmsScores.shoulderExternalRotationScoreRight !== '' && fmsScores.shoulderExternalRotationScoreRight !== undefined
        ? `Right: ${fmsScores.shoulderExternalRotationScoreRight}`
        : 'Right: —'}
    </span>
  </td>
  <td>
    {fmsScores.shoulderExternalRotationScoreLeft !== '' && fmsScores.shoulderExternalRotationScoreRight !== '' &&
    fmsScores.shoulderExternalRotationScoreLeft !== undefined && fmsScores.shoulderExternalRotationScoreRight !== undefined
      ? calculateFunctionalScore(fmsScores.shoulderExternalRotationScoreLeft, fmsScores.shoulderExternalRotationScoreRight)
      : '—'}
  </td>
</tr>


  {/* Calculated Shoulder Mobility Score (Unilateral) */}
<tr>
  <td>Shoulder Mobility Screen</td>
  <td>—</td>
  <td>
    <span>
      Left: {fmsScores.shoulderMobilityLeft !== '' && fmsScores.shoulderMobilityLeft !== undefined ? fmsScores.shoulderMobilityLeft : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.shoulderMobilityRight !== '' && fmsScores.shoulderMobilityRight !== undefined ? fmsScores.shoulderMobilityRight : '—'}
    </span>
  </td>
  <td>
    {fmsScores.shoulderMobilityLeft !== '' && fmsScores.shoulderMobilityRight !== '' &&
    fmsScores.shoulderMobilityLeft !== undefined && fmsScores.shoulderMobilityRight !== undefined
      ? calculateFunctionalScore(fmsScores.shoulderMobilityLeft, fmsScores.shoulderMobilityRight)
      : '—'}
  </td>
</tr>


  {/* Shoulder Impingement Clearing Test (Unilateral) */}
<tr>
  <td>Shoulder Impingement Clearing Test</td>
  <td className="unilateral-entry">
    <select
      value={fmsScores.shoulderImpingementClearingTestLeft}
      onChange={(e) => handleInputChange(e, 'shoulderImpingementClearingTestLeft')}
    >
      <option value=""></option>
      <option value="Pass">Pass</option>
      <option value="Fail">Fail</option>
    </select>
    <select
      value={fmsScores.shoulderImpingementClearingTestRight}
      onChange={(e) => handleInputChange(e, 'shoulderImpingementClearingTestRight')}
    >
      <option value=""></option>
      <option value="Pass">Pass</option>
      <option value="Fail">Fail</option>
    </select>
  </td>
  <td>
    <span>
      Left: {fmsScores.shoulderImpingementClearingTestLeft !== '' && fmsScores.shoulderImpingementClearingTestLeft !== undefined
        ? fmsScores.shoulderImpingementClearingTestLeft
        : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.shoulderImpingementClearingTestRight !== '' && fmsScores.shoulderImpingementClearingTestRight !== undefined
        ? fmsScores.shoulderImpingementClearingTestRight
        : '—'}
    </span>
  </td>
  <td>
    {fmsScores.shoulderImpingementClearingTestLeft !== '' && fmsScores.shoulderImpingementClearingTestRight !== '' &&
    fmsScores.shoulderImpingementClearingTestLeft !== undefined && fmsScores.shoulderImpingementClearingTestRight !== undefined
      ? (fmsScores.shoulderImpingementClearingTestLeft === 'Pass' && fmsScores.shoulderImpingementClearingTestRight === 'Pass'
          ? 'Pass'
          : 'Fail')
      : '—'}
  </td>
</tr>


{/* Shoulder Horizontal Adduction Clearing Test (Unilateral) */}
<tr>
  <td>Shoulder Horizontal Adduction Clearing Test</td>
  <td className="unilateral-entry">
    <select
      value={fmsScores.shoulderHorizontalAdductionClearingTestLeft}
      onChange={(e) => handleInputChange(e, 'shoulderHorizontalAdductionClearingTestLeft')}
    >
      <option value=""></option>
      <option value="Pass">Pass</option>
      <option value="Fail">Fail</option>
    </select>
    <select
      value={fmsScores.shoulderHorizontalAdductionClearingTestRight}
      onChange={(e) => handleInputChange(e, 'shoulderHorizontalAdductionClearingTestRight')}
    >
      <option value=""></option>
      <option value="Pass">Pass</option>
      <option value="Fail">Fail</option>
    </select>
  </td>
  <td>
    <span>
      Left: {fmsScores.shoulderHorizontalAdductionClearingTestLeft !== '' && fmsScores.shoulderHorizontalAdductionClearingTestLeft !== undefined
        ? fmsScores.shoulderHorizontalAdductionClearingTestLeft
        : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.shoulderHorizontalAdductionClearingTestRight !== '' && fmsScores.shoulderHorizontalAdductionClearingTestRight !== undefined
        ? fmsScores.shoulderHorizontalAdductionClearingTestRight
        : '—'}
    </span>
  </td>
  <td>
    {fmsScores.shoulderHorizontalAdductionClearingTestLeft !== '' && fmsScores.shoulderHorizontalAdductionClearingTestRight !== '' &&
    fmsScores.shoulderHorizontalAdductionClearingTestLeft !== undefined && fmsScores.shoulderHorizontalAdductionClearingTestRight !== undefined
      ? (fmsScores.shoulderHorizontalAdductionClearingTestLeft === 'Pass' && fmsScores.shoulderHorizontalAdductionClearingTestRight === 'Pass'
          ? 'Pass'
          : 'Fail')
      : '—'}
  </td>
</tr>


{/* Shoulder External Rotation Stability (Unilateral) */}
<tr>
  <td>Shoulder External Rotation Stability</td>
  <td className="unilateral-entry"> {/* Ensure this div uses the class */}
    <select value={fmsScores.shoulderExternalStabilityLeft} onChange={(e) => handleInputChange(e, 'shoulderExternalStabilityLeft')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
    <select value={fmsScores.shoulderExternalStabilityRight} onChange={(e) => handleInputChange(e, 'shoulderExternalStabilityRight')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>
    {/* Score calculations */}
    Left: {fmsScores.shoulderExternalStabilityLeft !== '' ? fmsScores.shoulderExternalStabilityLeft : '—'}
    &nbsp;Right: {fmsScores.shoulderExternalStabilityRight !== '' ? fmsScores.shoulderExternalStabilityRight : '—'}
  </td>
  <td>{calculateFunctionalScore(fmsScores.shoulderExternalStabilityLeft, fmsScores.shoulderExternalStabilityRight)}</td>
</tr>


{/* Flexed Shoulder External Rotation Stability (Unilateral) */}
<tr>
  <td>Flexed Shoulder External Rotation Stability</td>
  <td className="unilateral-entry"> {/* Ensure this div uses the class */}
    <select value={fmsScores.flexedShoulderExternalStabilityLeft} onChange={(e) => handleInputChange(e, 'flexedShoulderExternalStabilityLeft')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
    <select value={fmsScores.flexedShoulderExternalStabilityRight} onChange={(e) => handleInputChange(e, 'flexedShoulderExternalStabilityRight')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>
    Left: {fmsScores.flexedShoulderExternalStabilityLeft !== '' ? fmsScores.flexedShoulderExternalStabilityLeft : '—'}
    &nbsp;Right: {fmsScores.flexedShoulderExternalStabilityRight !== '' ? fmsScores.flexedShoulderExternalStabilityRight : '—'}
  </td>
  <td>{calculateFunctionalScore(fmsScores.flexedShoulderExternalStabilityLeft, fmsScores.flexedShoulderExternalStabilityRight)}</td>
</tr>


{/* Wrist Extension Mobility (Bilateral) */}
<tr>
  <td>Wrist Extension Mobility</td>
  <td>
    <select value={fmsScores.wristExtensionLeft} onChange={(e) => handleInputChange(e, 'wristExtensionLeft')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>
    {fmsScores.wristExtensionLeft !== '' && fmsScores.wristExtensionLeft !== undefined ? fmsScores.wristExtensionLeft : '—'}
  </td>
  <td>
    {fmsScores.wristExtensionLeft !== '' && fmsScores.wristExtensionLeft !== undefined ? fmsScores.wristExtensionLeft : '—'}
  </td>
</tr>



{/* Toe Touch Mobility (Bilateral) */}
<tr>
  <td>Toe Touch Mobility</td>
  <td>
    <select value={fmsScores.toeTouch} onChange={(e) => handleInputChange(e, 'toeTouch')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>{fmsScores.toeTouch || '—'}</td>
  <td>{fmsScores.toeTouch || '—'}</td>
</tr>

{/* Hip Extension Mobility (Unilateral) */}
<tr>
  <td>Hip Extension Mobility</td>
  <td className="unilateral-entry">
    <select value={fmsScores.hipExtensionLeft} onChange={(e) => handleInputChange(e, 'hipExtensionLeft')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
    <select value={fmsScores.hipExtensionRight} onChange={(e) => handleInputChange(e, 'hipExtensionRight')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>
    <span>
      Left: {fmsScores.hipExtensionLeft !== '' && fmsScores.hipExtensionLeft !== undefined ? fmsScores.hipExtensionLeft : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.hipExtensionRight !== '' && fmsScores.hipExtensionRight !== undefined ? fmsScores.hipExtensionRight : '—'}
    </span>
  </td>
  <td>
    {fmsScores.hipExtensionLeft !== '' && fmsScores.hipExtensionRight !== '' &&
    fmsScores.hipExtensionLeft !== undefined && fmsScores.hipExtensionRight !== undefined
      ? calculateFunctionalScore(fmsScores.hipExtensionLeft, fmsScores.hipExtensionRight)
      : '—'}
  </td>
</tr>


 {/* Hip Internal Rotation (Unilateral, Degrees) */}
<tr>
  <td>Hip Internal Rotation Mobility</td>
  <td>
    <div className="unilateral-entry">
      <input
        type="number"
        placeholder="Left (°)"
        value={fmsScores.hipInternalRotationLeft}
        onChange={(e) => handleInputChange(e, 'hipInternalRotationLeft')}
      />
      <input
        type="number"
        placeholder="Right (°)"
        value={fmsScores.hipInternalRotationRight}
        onChange={(e) => handleInputChange(e, 'hipInternalRotationRight')}
      />
    </div>
  </td>
  <td>
    Left: {fmsScores.hipInternalRotationScoreLeft !== '' ? fmsScores.hipInternalRotationScoreLeft : '—'}
    &nbsp;Right: {fmsScores.hipInternalRotationScoreRight !== '' ? fmsScores.hipInternalRotationScoreRight : '—'}
  </td>
  <td>{fmsScores.hipInternalRotationScoreLeft !== '' ? fmsScores.hipInternalRotationScoreRight : '—'}</td>
</tr>

{/* Hip External Rotation (Unilateral, Degrees) */}
<tr>
  <td>Hip External Rotation Mobility</td>
  <td>
    <div className="unilateral-entry">
      <input
        type="number"
        placeholder="Left (°)"
        value={fmsScores.hipExternalRotationLeft}
        onChange={(e) => handleInputChange(e, 'hipExternalRotationLeft')}
      />
      <input
        type="number"
        placeholder="Right (°)"
        value={fmsScores.hipExternalRotationRight}
        onChange={(e) => handleInputChange(e, 'hipExternalRotationRight')}
      />
    </div>
  </td>
  <td>
    Left: {fmsScores.hipExternalRotationScoreLeft !== '' ? fmsScores.hipExternalRotationScoreLeft : '—'}
    &nbsp;Right: {fmsScores.hipExternalRotationScoreRight !== '' ? fmsScores.hipExternalRotationScoreRight : '—'}
  </td>
  <td>{fmsScores.hipExternalRotationScoreRight !== '' ? fmsScores.hipExternalRotationScoreRight : '—'}</td>
</tr>


{/* Active Straight Leg Raise Screen (Unilateral) */}
<tr>
  <td>Active Straight Leg Raise Screen</td>
  <td className="unilateral-entry">
    <select value={fmsScores.activeStraightLegRaiseLeft} onChange={(e) => handleInputChange(e, 'activeStraightLegRaiseLeft')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
    <select value={fmsScores.activeStraightLegRaiseRight} onChange={(e) => handleInputChange(e, 'activeStraightLegRaiseRight')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>
    <span>
      Left: {fmsScores.activeStraightLegRaiseLeft !== '' && fmsScores.activeStraightLegRaiseLeft !== undefined ? fmsScores.activeStraightLegRaiseLeft : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.activeStraightLegRaiseRight !== '' && fmsScores.activeStraightLegRaiseRight !== undefined ? fmsScores.activeStraightLegRaiseRight : '—'}
    </span>
  </td>
  <td>
    {fmsScores.activeStraightLegRaiseLeft !== '' && fmsScores.activeStraightLegRaiseRight !== '' &&
    fmsScores.activeStraightLegRaiseLeft !== undefined && fmsScores.activeStraightLegRaiseRight !== undefined
      ? calculateFunctionalScore(fmsScores.activeStraightLegRaiseLeft, fmsScores.activeStraightLegRaiseRight)
      : '—'}
  </td>
</tr>


{/* Trunk Stability Push Up (Bilateral) */}
<tr>
  <td>Trunk Stability Push Up Screen</td>
  <td>
    <select value={fmsScores.trunkStabilityPushUp} onChange={(e) => handleInputChange(e, 'trunkStabilityPushUp')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>{fmsScores.trunkStabilityPushUp || '—'}</td>
  <td>{fmsScores.trunkStabilityPushUp || '—'}</td>
</tr>

{/* Spinal Extension Clearing Test (Bilateral) */}
<tr>
  <td>Spinal Extension Clearing Test</td>
  <td>
    <select value={fmsScores.spinalExtensionClearingTest} onChange={(e) => handleInputChange(e, 'spinalExtensionClearingTest')}>
      <option value=""></option>
      <option value="Fail">Fail</option>
      <option value="Pass">Pass</option>
    </select>
  </td>
  <td>{fmsScores.spinalExtensionClearingTest || '—'}</td>
  <td>{fmsScores.spinalExtensionClearingTest || '—'}</td>
</tr>

{/* Rotary Stability Screen (Unilateral) */}
<tr>
  <td>Rotary Stability Screen</td>
  <td className="unilateral-entry">
    <select value={fmsScores.rotaryStabilityLeft} onChange={(e) => handleInputChange(e, 'rotaryStabilityLeft')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
    <select value={fmsScores.rotaryStabilityRight} onChange={(e) => handleInputChange(e, 'rotaryStabilityRight')}>
      <option value=""></option>
      <option value={0}>0 - Pain</option>
      <option value={1}>1 - Insufficient</option>
      <option value={2}>2 - Sufficient</option>
      <option value={3}>3 - Optimal</option>
    </select>
  </td>
  <td>
    <span>
      Left: {fmsScores.rotaryStabilityLeft !== '' && fmsScores.rotaryStabilityLeft !== undefined ? fmsScores.rotaryStabilityLeft : '—'}
    </span>
    &nbsp;&nbsp;
    <span>
      Right: {fmsScores.rotaryStabilityRight !== '' && fmsScores.rotaryStabilityRight !== undefined ? fmsScores.rotaryStabilityRight : '—'}
    </span>
  </td>
  <td>
    {fmsScores.rotaryStabilityLeft !== '' && fmsScores.rotaryStabilityRight !== '' &&
    fmsScores.rotaryStabilityLeft !== undefined && fmsScores.rotaryStabilityRight !== undefined
      ? calculateFunctionalScore(fmsScores.rotaryStabilityLeft, fmsScores.rotaryStabilityRight)
      : '—'}
  </td>
</tr>


{/* Spinal Flexion Clearing Test (Bilateral) */}
<tr>
  <td>Spinal Flexion Clearing Test</td>
  <td>
    <select value={fmsScores.spinalFlexionClearingTest} onChange={(e) => handleInputChange(e, 'spinalFlexionClearingTest')}>
      <option value=""></option>
      <option value="Fail">Fail</option>
      <option value="Pass">Pass</option>
    </select>
  </td>
  <td>{fmsScores.spinalFlexionClearingTest || '—'}</td>
  <td>{fmsScores.spinalFlexionClearingTest || '—'}</td>
</tr>

{/* Hand Grip Strength (Unilateral) */}
<tr>
  <td>Hand Grip Strength</td>
  <td className="unilateral-entry">
    <input
      type="number"
      value={fmsScores.handGripStrengthLeft}
      onChange={(e) => handleInputChange(e, 'handGripStrengthLeft')}
      placeholder="Left (kg)"
    />
    <input
      type="number"
      value={fmsScores.handGripStrengthRight}
      onChange={(e) => handleInputChange(e, 'handGripStrengthRight')}
      placeholder="Right (kg)"
    />
  </td>
  <td>
  <span>
    Left: {fmsScores.leftGripStrengthScore !== '' && fmsScores.leftGripStrengthScore !== undefined ? fmsScores.leftGripStrengthScore : '—'}
  </span>
  &nbsp;&nbsp;
  <span>
    Right: {fmsScores.rightGripStrengthScore !== '' && fmsScores.rightGripStrengthScore !== undefined ? fmsScores.rightGripStrengthScore : '—'}
  </span>
</td>
  <td>
    {fmsScores.handGripStrengthLeft !== '' && fmsScores.handGripStrengthRight !== '' &&
    fmsScores.handGripStrengthLeft !== undefined && fmsScores.handGripStrengthRight !== undefined
      ? calculateFunctionalScore(fmsScores.leftGripStrengthScore, fmsScores.rightGripStrengthScore)
      : '—'}
  </td>
</tr>
</tbody>
</table>


{/* Load Previous Scores Button */}
<button type="button" onClick={loadPreviousScores}>Load Previous Scores</button>

      <button type="submit">Update FMS Scores</button>
      {successMessage && <p className="success-banner">{successMessage}</p>}
    </form>
  </div>
);
};

export default FMSForm;