import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from './firebase';
import AssignProgram from './AssignedProgram';

const ClientProgramHistory = ({ clientId }) => {
  const [currentProgram, setCurrentProgram] = useState(null);
  const [previousPrograms, setPreviousPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // To handle errors during fetching

  // Fetch program history from Firestore
  const fetchProgramHistory = async () => {
    setLoading(true);
    setError(null); // Reset error state before fetching

    try {
      const assignedProgramsRef = collection(db, `clients/${clientId}/assignedPrograms`);
      
      // Sort by startDate to ensure the latest programs are picked
      const programQuery = query(assignedProgramsRef, orderBy('startDate', 'desc'));
      const querySnapshot = await getDocs(programQuery);

      const assignedProgramsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Separate current and previous programs
      const currentProgramData = assignedProgramsList.find(program => program.status === 'current');
      const previousProgramsData = assignedProgramsList.filter(program => program.status === 'completed');

      setCurrentProgram(currentProgramData || null);
      setPreviousPrograms(previousProgramsData);
    } catch (error) {
      console.error('Error fetching program history:', error);
      setError('Failed to load program history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch program history when clientId changes
  useEffect(() => {
    if (clientId) {
      fetchProgramHistory();
    }
  }, [clientId]);

  // Refresh the program history after assigning a program
  const handleProgramAssigned = () => {
    fetchProgramHistory();
  };

  // Helper function to format dates
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } catch {
      return 'Invalid Date';
    }
  };

  if (loading) {
    return <p>Loading program history...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="program-history">
      <h2>Program History</h2>

      {/* Display the current program */}
      {currentProgram ? (
        <div className="current-program">
          <h3>Current Program</h3>
          <p><strong>Program Name:</strong> {currentProgram.programName}</p>
          <p><strong>Start Date:</strong> {formatDate(currentProgram.startDate)}</p> {/* Display Start Date */}
          <p><strong>Duration:</strong> {currentProgram.duration} weeks</p> {/* Display Duration */}
          <Link to={`/log-workout/${clientId}/${currentProgram.id}`}>Log Exercises</Link>
        </div>
      ) : (
        <p>No current program assigned.</p>
      )}

      {/* Display previous programs */}
      <h3>Previous Programs</h3>
      {previousPrograms.length > 0 ? (
        <table className="program-history-table">
          <thead>
            <tr>
              <th>Program Name</th>
              <th>Start Date</th>
              <th>Duration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {previousPrograms.map((program) => (
              <tr key={program.id}>
                <td>{program.programName}</td>
                <td>{formatDate(program.startDate)}</td>
                <td>{program.duration} weeks</td>
                <td>
                  <Link to={`/log-workout/${clientId}/${program.id}`}>Log Exercises</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No previous programs found.</p>
      )}
    </div>
  );
};

export default ClientProgramHistory;
