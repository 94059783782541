import React, { useState } from 'react';
import ClientDropdown from './ClientDropdown';
import { fetchClientData } from './fetchClientData'; // Reference to your existing fetchClientData function
import './GroupTrainingGrid.css';
import { submitExerciseData, submitCompletionTag } from './submitExerciseData';
import { Link } from 'react-router-dom';

const GroupTrainingGrid = () => {
  const [selectedClients, setSelectedClients] = useState(Array(6).fill(null));
  const [clientData, setClientData] = useState(Array(6).fill(null));
  const [exerciseData, setExerciseData] = useState(Array(6).fill([]));
  const [submittedWeeks, setSubmittedWeeks] = useState({});
  const [successMessages, setSuccessMessages] = useState(Array(6).fill(''));
  const [isSubmitting, setIsSubmitting] = useState(Array(6).fill(false)); // Loading state for submission
  const [isExpanded, setIsExpanded] = useState(Array(6).fill(false)); // Collapsible state for each client

  // Function to toggle the expand/collapse state
  const toggleExpandCollapse = (index) => {
    const updatedExpandState = [...isExpanded];
    updatedExpandState[index] = !updatedExpandState[index]; // Toggle the state
    setIsExpanded(updatedExpandState);
  };

  const handleClientSelect = async (index, client) => {
    try {
      const updatedClients = [...selectedClients];
      updatedClients[index] = client;
      setSelectedClients(updatedClients);

      // Fetch client data using fetchClientData
      const data = await fetchClientData(client.id);
      console.log('Fetched client data:', data); // Debugging: Log the fetched client data

      const updatedClientData = [...clientData];
      updatedClientData[index] = data;
      setClientData(updatedClientData);

      // Automatically expand the grid when a client is selected
      const updatedExpandState = [...isExpanded];
      updatedExpandState[index] = true; // Automatically expand the selected client
      setIsExpanded(updatedExpandState);

      if (data?.firstWorkout?.exercises && Array.isArray(data.firstWorkout.exercises)) {
        const updatedExerciseData = [...exerciseData];
        updatedExerciseData[index] = data.firstWorkout.exercises.map((exerciseDoc) => ({
          exerciseId: exerciseDoc.id,
          exerciseName: exerciseDoc.exerciseName,
          equipment: exerciseDoc.equipment,
          sets: exerciseDoc.sets,
          reps: exerciseDoc.reps,
          lbs: exerciseDoc.lbs,
        }));
        setExerciseData(updatedExerciseData);
      } else {
        const updatedExerciseData = [...exerciseData];
        updatedExerciseData[index] = [];
        setExerciseData(updatedExerciseData);
      }
    } catch (error) {
      console.error('Error in handleClientSelect:', error);
    }
  };

  const handleExerciseChange = (clientIndex, exerciseIndex, setIndex, field, value) => {
    const updatedExerciseData = [...exerciseData];
    const exercisesForClient = updatedExerciseData[clientIndex] || [];
    const exercise = exercisesForClient[exerciseIndex] || {};

    const currentWeek = clientData[clientIndex]?.firstWorkout?.week - 1;

    if (!exercise[field]) {
      exercise[field] = [];
    }
    if (!Array.isArray(exercise[field][currentWeek])) {
      exercise[field][currentWeek] = [];
    }

    exercise[field][currentWeek][setIndex] = value;
    setExerciseData(updatedExerciseData);

    console.log("Updated exercise data:", updatedExerciseData);
  };

  const handleSubmitWorkout = async (clientIndex, workoutId, exercises) => {
    try {
      setIsSubmitting((prev) => {
        const updatedSubmitting = [...prev];
        updatedSubmitting[clientIndex] = true; // Mark the workout as being submitted
        return updatedSubmitting;
      });
  
      const clientDataForIndex = clientData[clientIndex];
      if (!clientDataForIndex?.firstWorkout) {
        console.error('No workout data available');
        return;
      }
  
      const { programId, mesoCycleId, mesoCycleNumber, workoutNumber, week } = clientDataForIndex.firstWorkout;
      const exercisesForClient = exerciseData[clientIndex];
  
      if (!Array.isArray(exercisesForClient)) {
        console.error('Invalid exercise data');
        return;
      }
  
      // Loop through each exercise
      for (let exerciseIndex = 0; exerciseIndex < exercisesForClient.length; exerciseIndex++) {
        const exercise = exercisesForClient[exerciseIndex];
        if (!exercise?.exerciseId || !Array.isArray(exercise.sets)) continue;
  
        const setsForCurrentWeek = exercise.sets?.[week - 1] || 0;
        const repsForCurrentWeek = exercise.reps?.[week - 1] || Array(setsForCurrentWeek).fill(0);
        const lbsForCurrentWeek = exercise.lbs?.[week - 1] || Array(setsForCurrentWeek).fill('0');
  
        // Prepare data for each set
        for (let setIndex = 0; setIndex < setsForCurrentWeek; setIndex++) {
          const reps = repsForCurrentWeek[setIndex];
          const lbs = lbsForCurrentWeek[setIndex];
  
          console.log('Submitting set for exercise:', {
            exerciseId: exercise.exerciseId,
            setIndex: setIndex + 1,
            reps,
            lbs,
          });
  
          // Submit each set's data individually
          if (reps !== undefined && lbs !== undefined) {
            await submitExerciseData({
              clientId: selectedClients[clientIndex].id,
              programId,
              mesoCycleId,
              mesoCycleNumber,
              workoutId,
              workoutNumber,
              exerciseId: exercise.exerciseId,
              sets: setsForCurrentWeek,
              reps: [reps],  // Send reps for the specific set
              lbs: [lbs],    // Send lbs for the specific set
              week,
              setIndex  // Specify the set index here
            });
          }
        }
      }
  
      // Submit completion tag (if needed)
      await submitCompletionTag({
        clientId: selectedClients[clientIndex].id,
        programId,
        mesoCycleNumber,
        workoutNumber,
        week,
      });
  
      console.log(`Completion tag submitted: MesoCycle ${mesoCycleNumber}, Workout ${workoutNumber}, Week ${week}`);
  
      // Fetch updated client data after workout submission
      const updatedClientData = await fetchClientData(selectedClients[clientIndex].id);
      console.log('Fetched next workout after submission:', updatedClientData);
  
      const newClientData = [...clientData];
      newClientData[clientIndex] = updatedClientData;
      setClientData(newClientData);
  
      const updatedExerciseData = [...exerciseData];
      updatedExerciseData[clientIndex] = updatedClientData.firstWorkout.exercises.map((exerciseDoc) => ({
        exerciseId: exerciseDoc.id,
        exerciseName: exerciseDoc.exerciseName,
        equipment: exerciseDoc.equipment,
        sets: exerciseDoc.sets,
        reps: exerciseDoc.reps,
        lbs: exerciseDoc.lbs,
      }));
      setExerciseData(updatedExerciseData);
  
      const updatedMessages = [...successMessages];
      updatedMessages[clientIndex] = 'Workout successfully logged with completion tag!';
      setSuccessMessages(updatedMessages);
  
      setSubmittedWeeks((prev) => ({
        ...prev,
        [`${clientIndex}`]: true,
      }));
  
    } catch (error) {
      console.error('Error submitting workout data:', error);
    } finally {
      setIsSubmitting((prev) => {
        const updatedSubmitting = [...prev];
        updatedSubmitting[clientIndex] = false; // Unmark the submission state
        return updatedSubmitting;
      });
    }
  };
  

  return (
    <div className="group-training-grid">
      {selectedClients.map((client, clientIndex) => {
        const currentClientData = clientData[clientIndex];
        const currentWeek = currentClientData?.firstWorkout?.week - 1;

        return (
          <div key={clientIndex} className="grid-cell">
            <div className="client-header">
              <ClientDropdown
                selectedClient={client}
                onSelect={(client) => handleClientSelect(clientIndex, client)}
              />

              {/* Expand/Collapse button moved next to ClientDropdown */}
              <button 
                className="toggle-button" 
                onClick={() => toggleExpandCollapse(clientIndex)}
              >
                {isExpanded[clientIndex] ? 'Collapse' : 'Expand'}
              </button>
            </div>

            {/* Only show the client grid content if expanded */}
            {isExpanded[clientIndex] && currentClientData?.program && (
              <div className="client-content">
                <div className="client-buttons">
  <button 
    className="view-profile-button"
    onClick={() => window.open(`/client/${client?.id}`, '_blank')}
  >
    View Profile
  </button>
</div>
                <p className="assigned-program">Assigned Program: {currentClientData.program.programName || 'No Program Assigned'}</p>
                {currentClientData?.firstWorkout ? (
                  <>
                    <p className="current-workout">
                      Current Workout: MesoCycle {currentClientData.firstWorkout.mesoCycleNumber},
                      Workout {currentClientData.firstWorkout.workoutNumber}, Week {currentClientData.firstWorkout.week}
                    </p>

                    <table className="exercise-table">
                      <thead>
                        <tr>
                          <th colSpan="3" className="workout-title">
                            Workout {currentClientData.firstWorkout.workoutNumber}
                          </th>
                          {Array.isArray(exerciseData[clientIndex]) &&
                            exerciseData[clientIndex].length > 0 &&
                            [...Array(Math.max(...exerciseData[clientIndex].map(exercise => exercise.sets?.[currentWeek] || 0)))].map((_, setIndex) => (
                              <th key={`set_${setIndex}`} colSpan="2" style={{ textAlign: "center" }}>
                                Set {setIndex + 1}
                              </th>
                            ))}
                        </tr>

                        <tr className="header-row">
                          <th>Equipment</th>
                          <th>Exercise</th>
                          <th>Sets</th>
                          {Array.isArray(exerciseData[clientIndex]) &&
                            exerciseData[clientIndex].length > 0 &&
                            [...Array(Math.max(...exerciseData[clientIndex].map(exercise => exercise.sets?.[currentWeek] || 0)))].map((_, setIndex) => (
                              <React.Fragment key={`reps_lbs_${setIndex}`}>
                                <th>Reps</th>
                                <th>lbs.</th>
                              </React.Fragment>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
  {Array.isArray(exerciseData[clientIndex]) && exerciseData[clientIndex].length > 0 ? (
    exerciseData[clientIndex].map((exercise, exerciseIndex) => {
      const setsForCurrentWeek = exercise.sets?.[currentWeek] || 0;
      const repsForCurrentWeek = exercise.reps?.[currentWeek] || Array(setsForCurrentWeek).fill('');
      const lbsForCurrentWeek = exercise.lbs?.[currentWeek] || Array(setsForCurrentWeek).fill('');

      return (
        <tr key={`${exercise.exerciseId}-${exerciseIndex}`}>
          <td>{exercise.equipment}</td>
          <td>{exercise.exerciseName}</td>
          <td>{setsForCurrentWeek}</td>

          {Array.from({ length: setsForCurrentWeek }).map((_, setIndex) => (
            <React.Fragment key={`set_${exerciseIndex}_${setIndex}`}>
              <td>
                <input
                  type="number"
                  placeholder="Reps"
                  value={repsForCurrentWeek[setIndex] || ''}  // Correctly access the reps for the current week
                  onChange={(e) =>
                    handleExerciseChange(clientIndex, exerciseIndex, setIndex, 'reps', e.target.value)
                  }
                />
              </td>

              <td>
                <input
                  type="number"
                  placeholder="lbs."
                  value={lbsForCurrentWeek[setIndex] || ''}
                  onChange={(e) =>
                    handleExerciseChange(clientIndex, exerciseIndex, setIndex, 'lbs', e.target.value)
                  }
                />
              </td>
            </React.Fragment>
          ))}
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="3">No exercise data available</td>
    </tr>
  )}
</tbody>

                    </table>

                    <button
                      onClick={() => handleSubmitWorkout(
                        clientIndex,
                        currentClientData.firstWorkout.workoutId,
                        currentClientData.firstWorkout.exercises
                      )}
                      disabled={isSubmitting[clientIndex]} // Disable the button while submitting
                    >
                      {isSubmitting[clientIndex] ? 'Submitting workout...' : 'Submit Workout'}
                    </button>
                    {successMessages[clientIndex] && (
                      <p className="success-message">{successMessages[clientIndex]}</p>
                    )}
                  </>
                ) : (
                  <p>No unlogged workout available or no more mesocycles.</p>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GroupTrainingGrid;
