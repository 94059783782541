import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { db } from './firebase'; // Import Firestore instance (db)
import { collection, getDocs } from 'firebase/firestore'; // Firestore functions

const ClientDropdown = ({ selectedClient, onSelect }) => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true); // State to show loading status

  // Fetch clients from Firestore when the component loads
  useEffect(() => {
    const fetchClientsFromFirestore = async () => {
      try {
        const clientCollection = collection(db, 'clients'); // Correct way to get the 'clients' collection
        const snapshot = await getDocs(clientCollection); // Fetch all documents
        const fetchedClients = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClients(fetchedClients); // Update state with the fetched clients
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        console.error('Error fetching clients:', error);
        setLoading(false); // Stop loading in case of error
      }
    };

    fetchClientsFromFirestore();
  }, []);

  // Handle client selection
  const handleClientChange = (selectedOption) => {
    onSelect(selectedOption.value); // Pass the selected client to the parent component
  };

  // Map the client data into dropdown options
  const clientOptions = clients.map((client) => ({
    value: client, // Store the entire client object in the value
    label: client.fullName, // Display fullName in the dropdown
  }));

  return (
    <div className="client-dropdown-container">
      {loading ? (
        <p>Loading clients...</p> // Show loading while fetching data
      ) : (
        <Select
          value={selectedClient ? { label: selectedClient.fullName, value: selectedClient } : null}
          options={clientOptions}
          onChange={handleClientChange}
          placeholder="Select Client"
          className="react-select-container"
          classNamePrefix="react-select"
        />
      )}
    </div>
  );
};

export default ClientDropdown;
