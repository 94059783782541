import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import ClientInfo from './ClientInfo';
import WorkoutHistory from './WorkoutHistory';
import AssignedProgram from './AssignedProgram';
import FMSSection from './FMSSection';
import ClientProgramHistory from './ClientProgramHistory'; // Import ClientProgramHistory
import './ClientProfile.css'; // Ensure ClientProfile.css is imported
import ClientExerciseRecord from './ClientExerciseRecord'; // Import the new ClientExerciseRecord component

const ClientProfile = () => {
  const { id } = useParams();
  const [clientData, setClientData] = useState(null);
  const [clientLoading, setClientLoading] = useState(true);
  const [fmsLoading, setFmsLoading] = useState(false); // Avoid starting in loading mode unless needed

  // Fetch client data
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const clientRef = doc(db, 'clients', id);
        const clientSnap = await getDoc(clientRef);
        if (clientSnap.exists()) {
          setClientData({ id: clientSnap.id, ...clientSnap.data() });
        } else {
          console.error('No such client!');
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
      } finally {
        setClientLoading(false); // Ensure client loading stops once data is fetched
      }
    };

    fetchClientData();
  }, [id]);

  if (clientLoading) {
    return <p>Loading client data...</p>;
  }

  if (!clientData) {
    return <p>No client data found.</p>;
  }

  const { fullName, gender, birthDate } = clientData;
  const age = new Date().getFullYear() - new Date(birthDate).getFullYear();

  return (
    <div className="client-profile-container"> {/* Container for styling consistency */}
      <h1>{fullName}'s Profile</h1>

      {/* Client Information Section */}
      <div className="client-profile-section">
        <ClientInfo clientId={id} />
      </div>

      {/* Workout History Section */}
      <div className="client-profile-section">
        <WorkoutHistory clientId={id} />
      </div>

      {/* Assigned Program Section */}
      <div className="client-profile-section">
        <AssignedProgram clientId={id} />
      </div>

      {/* Program History Section */}
      <div className="client-profile-section">
        <ClientProgramHistory clientId={id} />
      </div>
       {/* Exercise Record Section */}
       <div className="client-profile-section"> {/* Adding the new Exercise Record section */}
        <ClientExerciseRecord clientId={id} />
      </div>

      {/* FMS Section */}
      <div className="client-profile-section">
        <FMSSection clientId={id} gender={gender} age={age} onFmsLoad={() => setFmsLoading(false)} />
        {fmsLoading ? <p>Loading FMS data...</p> : null}
      </div>

    </div>
  );
};

export default ClientProfile;
