import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, collection, getDoc, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import './ProgramDetail.css';

const ProgramDetail = () => {
  const { id } = useParams();
  const [program, setProgram] = useState(null);
  const [mesocycles, setMesocycles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProgram();
  }, [id]);

  const fetchProgram = async () => {
    try {
      const programRef = doc(db, 'programs', id);
      const programSnap = await getDoc(programRef);
  
      if (programSnap.exists()) {
        const programData = programSnap.data();
        setProgram({ id: programSnap.id, ...programData });
  
        // Fetch mesocycles
        const mesocyclesRef = collection(db, `programs/${programSnap.id}/mesoCycles`);
        const mesocyclesSnap = await getDocs(mesocyclesRef);
  
        const mesocyclesData = await Promise.all(
          mesocyclesSnap.docs.map(async (mesoDoc) => {
            const mesoData = mesoDoc.data();
  
            // Fetch workouts for each mesocycle
            const workoutsRef = collection(db, `programs/${programSnap.id}/mesoCycles/${mesoDoc.id}/workouts`);
            const workoutsSnap = await getDocs(workoutsRef);
  
            const workoutsData = await Promise.all(
              workoutsSnap.docs.map(async (workoutDoc) => {
                const workoutData = workoutDoc.data();
  
                // Fetch exercises for each workout
                const exercisesRef = collection(db, `programs/${programSnap.id}/mesoCycles/${mesoDoc.id}/workouts/${workoutDoc.id}/exercises`);
                const exercisesSnap = await getDocs(exercisesRef);
  
                const exercisesData = exercisesSnap.docs.map((exerciseDoc) => ({
                  id: exerciseDoc.id,
                  exerciseName: exerciseDoc.data().exerciseName,
                  equipment: exerciseDoc.data().equipment || 'N/A',
                  sets: exerciseDoc.data().sets,
                  reps: exerciseDoc.data().reps,
                }));
  
                return { ...workoutData, exercises: exercisesData };
              })
            );
  
            // Sort workouts by workoutNumber
            workoutsData.sort((a, b) => a.workoutNumber - b.workoutNumber);
  
            return { ...mesoData, workouts: workoutsData };
          })
        );
  
        // Sort mesocycles by mesocycleNumber
        mesocyclesData.sort((a, b) => a.mesocycleNumber - b.mesocycleNumber);
        setMesocycles(mesocyclesData);
      } else {
        console.error('No such program!');
      }
    } catch (error) {
      console.error('Error fetching program details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const renderWorkouts = (workouts, mesocycleDuration, mesocycleNumber) => (
    <>
      {workouts.map((workout, workoutIdx) => (
        <div key={workoutIdx} className="workout-container">
          <table className="custom-table">
            <thead>
              <tr>
                <th colSpan={2} className="workout-header">
                  Workout {workout.workoutNumber}
                </th>
                {Array.from({ length: mesocycleDuration }).map((_, weekIdx) => (
                  <th colSpan="2" key={weekIdx} className="week-header">
                    Week {weekIdx + 1 + (mesocycleNumber - 1) * mesocycleDuration}
                  </th>
                ))}
              </tr>
              <tr>
                <th className="equipment">Equipment</th>
                <th className="exercise">Exercise</th>
                {Array.from({ length: mesocycleDuration }).map((_, weekIdx) => (
                  <>
                    <th key={`sets-${weekIdx}`} className="sets-header">
                      Sets
                    </th>
                    <th key={`reps-${weekIdx}`} className="reps-header">
                      Reps
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {workout.exercises.map((exercise, exerciseIdx) => (
                <tr key={exerciseIdx}>
                  <td className="equipment">{exercise.equipment || 'N/A'}</td>
                  <td className="exercise">{exercise.exerciseName || 'N/A'}</td>
                  {Array.from({ length: mesocycleDuration }).map((_, weekIdx) => (
                    <>
                      <td key={`sets-${exerciseIdx}-${weekIdx}`} className="sets-data">
                        {exercise.sets && exercise.sets[weekIdx] ? exercise.sets[weekIdx] : 'N/A'}
                      </td>
                      <td key={`reps-${exerciseIdx}-${weekIdx}`} className="reps-data">
                        {exercise.reps && exercise.reps[weekIdx] ? exercise.reps[weekIdx] : 'N/A'}
                      </td>
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  );

  if (loading) {
    return <p>Loading program details...</p>;
  }

  return (
    <div className="program-details-container">
   <Link to="/program-manager" className="button">
  ← Back to Program Manager
</Link>


      {program && (
        <div className="program-header">
          <div className="program-details">
            <h2>{program.programName} Program</h2>
            <p>Duration: {program.macrocycleDuration} weeks</p>
            <p>Training Frequency: {program.trainingFrequency} workouts/week</p>
            <p>Exercises per Workout: {program.exercisesPerWorkout}</p>
            <p>Training Goal: {program.trainingGoal}</p>
            <p>Experience Level: {program.experienceLevel}</p>
            <p>Program Type: {program.programType}</p>
          </div>

          {/* Print Button */}
          <button onClick={handlePrint} className="print-button">
            Print Program
          </button>
        </div>
      )}

      {/* Render Mesocycles */}
      {mesocycles.length > 0 ? (
        mesocycles.map((mesocycle, mesoIdx) => {
          const mesocycleDuration = program.macrocycleDuration / program.mesocycles;

          return (
            <div key={mesoIdx} className="program-section">
              <h3>Mesocycle {mesocycle.mesocycleNumber}</h3>
              {renderWorkouts(mesocycle.workouts, mesocycleDuration, mesocycle.mesocycleNumber)}
            </div>
          );
        })
      ) : (
        <p>No mesocycles found for this program.</p>
      )}
    </div>
  );
};

export default ProgramDetail;
