import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import Select from 'react-select';  
import { db } from './firebase'; 

const AssignProgram = ({ clientId, onProgramAssigned }) => {
  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch the list of programs from Firestore
  const fetchPrograms = async () => {
    try {
      const programCollection = collection(db, 'programs'); 
      const programSnapshot = await getDocs(programCollection);
      const programList = programSnapshot.docs.map(doc => ({
        id: doc.id,
        label: doc.data().programName, 
        value: doc.id,
        ...doc.data() 
      }));
      setPrograms(programList);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);
  
  const handleAssignProgram = async () => {
    if (!selectedProgram) return;
  
    setLoading(true);
    setSuccessMessage('');
  
    try {
      // Fetch the currently assigned program with status 'current'
      const assignedProgramsRef = collection(db, `clients/${clientId}/assignedPrograms`);
      const assignedProgramsSnap = await getDocs(assignedProgramsRef);
      const currentProgramDoc = assignedProgramsSnap.docs.find(doc => doc.data().status === 'current');
  
      // If a current program exists, update its status to 'completed'
      if (currentProgramDoc) {
        const currentProgramRef = doc(db, `clients/${clientId}/assignedPrograms/${currentProgramDoc.id}`);
        await updateDoc(currentProgramRef, { status: 'completed' });
      }
  
      // Fetch full program details for the new program
      const programRef = doc(db, 'programs', selectedProgram.value);
      const programSnap = await getDoc(programRef);
      if (!programSnap.exists()) {
        console.error('Program not found.');
        return;
      }
  
      const programData = programSnap.data(); 
  
      // Create unique assigned program for the client
      const newAssignedProgramRef = await addDoc(assignedProgramsRef, {
        programId: selectedProgram.value,
        programName: selectedProgram.label,
        startDate: startDate,
        status: 'current',
        duration: programData.macrocycleDuration, // Assuming macrocycleDuration exists
        workoutFrequency: programData.trainingFrequency, // Assuming trainingFrequency exists
      });
  
      const assignedProgramId = newAssignedProgramRef.id;
  
      // Now copy mesocycles, workouts, and exercises to the client's unique assigned program
      const mesoCyclesRef = collection(db, `programs/${selectedProgram.value}/mesoCycles`);
      const mesoCyclesSnap = await getDocs(mesoCyclesRef);
  
      for (const mesoDoc of mesoCyclesSnap.docs) {
        const mesoCycleData = mesoDoc.data();
        
        // Create a unique mesoCycle in the client's assigned program
        const mesoCycleRef = await addDoc(collection(db, `clients/${clientId}/assignedPrograms/${assignedProgramId}/mesoCycles`), mesoCycleData);
        
        const workoutsRef = collection(db, `programs/${selectedProgram.value}/mesoCycles/${mesoDoc.id}/workouts`);
        const workoutsSnap = await getDocs(workoutsRef);
        
        for (const workoutDoc of workoutsSnap.docs) {
          const workoutData = workoutDoc.data();
          
          // Create unique workouts in the client's mesoCycle
          const workoutRef = await addDoc(collection(db, `clients/${clientId}/assignedPrograms/${assignedProgramId}/mesoCycles/${mesoCycleRef.id}/workouts`), workoutData);
          
          const exercisesRef = collection(db, `programs/${selectedProgram.value}/mesoCycles/${mesoDoc.id}/workouts/${workoutDoc.id}/exercises`);
          const exercisesSnap = await getDocs(exercisesRef);
          
          for (const exerciseDoc of exercisesSnap.docs) {
            const exerciseData = exerciseDoc.data();
  
            // Create unique exercises in the client's workout
            await addDoc(collection(db, `clients/${clientId}/assignedPrograms/${assignedProgramId}/mesoCycles/${mesoCycleRef.id}/workouts/${workoutRef.id}/exercises`), {
              ...exerciseData,
              weeks: [], // Add empty 'weeks' field for logging sets, reps, and weights
            });
          }
        }
      }
  
      setSuccessMessage(`Program "${selectedProgram.label}" assigned successfully!`);
  
      // Call the callback to refresh the UI
      if (onProgramAssigned) {
        onProgramAssigned();
      }
  
      // Automatically refresh the page after a short delay
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Adjust the delay if needed (currently 2 seconds)
  
      setSelectedProgram(null);
  
    } catch (error) {
      console.error('Error assigning program:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="assign-program">
      <h3>Assign Program</h3>
      <Select
        options={programs}
        value={selectedProgram}
        onChange={setSelectedProgram}
        placeholder="Select a program"
        isSearchable
      />
      <label>Start Date:</label>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <button onClick={handleAssignProgram} disabled={!selectedProgram || loading}>
        {loading ? 'Assigning Program...' : 'Assign Program'}
      </button>
      {successMessage && <p>{successMessage}</p>}
    </div>
  );
};

export default AssignProgram;
