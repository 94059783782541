import {arrayUnion, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase'; 

export const submitExerciseData = async ({
  clientId,
  programId,
  mesoCycleId,
  mesoCycleNumber,
  workoutId,
  workoutNumber,
  exerciseId,
  sets,  // Total number of sets for the exercise (for reference)
  reps,  // Array with a single entry for reps of this specific set
  lbs,   // Array with a single entry for lbs of this specific set
  week,  // Current week (1, 2, 3, etc.)
  setIndex // Set index to update within the current week
}) => {
  try {
    console.log('Submitting data for exercise:', {
      clientId,
      programId,
      mesoCycleId,
      mesoCycleNumber,
      workoutId,
      workoutNumber,
      exerciseId,
      setIndex,  // Log the setIndex for clarity
      reps: reps[0],  // Log single value for clarity
      lbs: lbs[0],    // Log single value for clarity
      week,
    });

    if (!exerciseId) {
      throw new Error("Exercise ID is undefined. Cannot submit workout data.");
    }

    // Reference to the specific exercise document under the client's assigned program
    const exerciseDocRef = doc(
      db,
      `clients/${clientId}/assignedPrograms/${programId}/mesoCycles/${mesoCycleId}/workouts/${workoutId}/exercises/${exerciseId}`
    );

    console.log('Exercise Document Ref:', exerciseDocRef.path);

    const exerciseDocSnapshot = await getDoc(exerciseDocRef);
    if (!exerciseDocSnapshot.exists()) {
      console.error("Exercise document not found.");
      return;
    }

    const exerciseData = exerciseDocSnapshot.data();
    let currentWeeks = exerciseData.weeks || [];

    // Ensure the weeks array has the right size (initialize empty weeks if needed)
    while (currentWeeks.length < week) {
      currentWeeks.push({ sets: [] }); // Initialize empty sets array for new week
    }

    const weekIndex = week - 1; // 0-based index for array, week is 1-based
    if (!Array.isArray(currentWeeks[weekIndex].sets)) {
      currentWeeks[weekIndex].sets = [];
    }

    console.log(`Week ${weekIndex + 1} before update:`, currentWeeks[weekIndex]);

    // Ensure the sets array within the week has the correct number of entries
    while (currentWeeks[weekIndex].sets.length <= setIndex) {
      currentWeeks[weekIndex].sets.push({ reps: 0, lbs: '0' });
    }

    // Update the specific set with reps and lbs values
    currentWeeks[weekIndex].sets[setIndex] = {
      reps: Number(reps[0] || 0),  // Store the reps value for this set
      lbs: lbs[0] || '0',          // Store the lbs value for this set
    };

    console.log(`Updated Set ${setIndex + 1} for Week ${weekIndex + 1}: Reps: ${reps[0]}, lbs: ${lbs[0]}`);

    // Prepare the update data with the updated weeks array
    const updateData = {
      weeks: currentWeeks,
    };

    // Update the exercise document in Firestore with the updated weeks data
    await updateDoc(exerciseDocRef, updateData);
    console.log(`Exercise data successfully updated for exercise: ${exerciseId}`);
    console.log(`Updated week ${weekIndex + 1} data:`, currentWeeks[weekIndex]);

  } catch (error) {
    console.error('Error submitting workout data:', error);
  }
};




export const submitCompletionTag = async ({
  clientId,
  programId,
  mesoCycleNumber,  // M in M_W_X
  workoutNumber,    // W in M_W_X
  week,             // X in M_W_X
}) => {
  try {
    console.log('Submitting completion tag with the following details:');
    console.log('MesoCycle Number:', mesoCycleNumber);
    console.log('Workout Number:', workoutNumber);
    console.log('Week:', week);

    // Pad numbers to ensure they're three digits
    const paddedMesoCycleNumber = String(mesoCycleNumber).padStart(3, '0');
    const paddedWorkoutNumber = String(workoutNumber).padStart(3, '0');
    const paddedWeek = String(week).padStart(3, '0');

    const workoutTag = `${paddedMesoCycleNumber}_${paddedWorkoutNumber}_${paddedWeek}`;

    const assignedProgramDocRef = doc(db, `clients/${clientId}/assignedPrograms/${programId}`);

    await updateDoc(assignedProgramDocRef, {
      completedTags: arrayUnion(workoutTag), // Add the padded workout tag to completedTags array
    });

    console.log('Workout completion tag added:', workoutTag);
  } catch (error) {
    console.error('Error adding workout completion tag:', error);
  }
};
