import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from './firebase';
import './ProgramDatabase.css';

const ProgramDatabase = ({ onEditProgram, programs, onProgramsUpdated }) => {
  const [mesocycleCounts, setMesocycleCounts] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    goal: '',
    experience: '',
    duration: '',
    frequency: '',
    programType: '',
  });

  const trainingGoals = ['Hypertrophy', 'Strength', 'Endurance', 'Functionality'];
  const experienceLevels = ['Beginner', 'Intermediate', 'Advanced'];
  const durations = [1, 2, 4, 6, 8, 12];
  const frequencies = [2, 3, 4, 5, 6];
  const programTypes = ['Dynamic', 'Static'];

  // Fetch mesocycle counts whenever the programs prop changes
  useEffect(() => {
    fetchMesocycleCounts();
  }, [programs]);

  // Function to fetch mesocycle counts for each program
  const fetchMesocycleCounts = async () => {
    const mesoCounts = {};
    for (const program of programs) {
      const mesocyclesCollection = collection(db, `programs/${program.id}/mesoCycles`);
      const mesocyclesSnapshot = await getDocs(mesocyclesCollection);
      mesoCounts[program.id] = mesocyclesSnapshot.docs.length;
    }
    setMesocycleCounts(mesoCounts);
  };

  // Filter programs based on search term and filters
  const filteredPrograms = programs.filter((program) => {
    const matchesSearchTerm = program.programName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesGoal = filters.goal === '' || program.trainingGoal === filters.goal;
    const matchesExperience = filters.experience === '' || program.experienceLevel === filters.experience;
    const matchesDuration = filters.duration === '' || program.macrocycleDuration === Number(filters.duration);
    const matchesFrequency = filters.frequency === '' || program.trainingFrequency === Number(filters.frequency);
    const matchesProgramType = filters.programType === '' || program.programType === filters.programType;

    return (
      matchesSearchTerm &&
      matchesGoal &&
      matchesExperience &&
      matchesDuration &&
      matchesFrequency &&
      matchesProgramType
    );
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditProgram = (program) => {
    onEditProgram(program);
  };

  const handleDeleteProgram = async (programId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this program?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'programs', programId));
        onProgramsUpdated(); // Trigger a refresh in the parent component after deletion
        alert('Program deleted successfully!');
      } catch (error) {
        console.error('Error deleting program:', error);
        alert('Failed to delete the program. Please try again.');
      }
    }
  };

  return (
    <div className="program-database-container">
      <h2>Program Database</h2>

      {/* Search bar */}
      <input
        type="text"
        placeholder="Search Program Name"
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-bar"
      />

      {/* Filters */}
      <div className="filters-container">
        <select name="goal" value={filters.goal} onChange={handleFilterChange} className="filter-select">
          <option value="">All Goals</option>
          {trainingGoals.map((goal) => (
            <option key={goal} value={goal}>
              {goal}
            </option>
          ))}
        </select>

        <select name="experience" value={filters.experience} onChange={handleFilterChange} className="filter-select">
          <option value="">All Experience Levels</option>
          {experienceLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </select>

        <select name="duration" value={filters.duration} onChange={handleFilterChange} className="filter-select">
          <option value="">All Durations</option>
          {durations.map((duration) => (
            <option key={duration} value={duration}>
              {duration} Weeks
            </option>
          ))}
        </select>

        <select name="frequency" value={filters.frequency} onChange={handleFilterChange} className="filter-select">
          <option value="">All Frequencies</option>
          {frequencies.map((freq) => (
            <option key={freq} value={freq}>
              {freq} Workouts/Week
            </option>
          ))}
        </select>

        <select name="programType" value={filters.programType} onChange={handleFilterChange} className="filter-select">
          <option value="">All Program Types</option>
          {programTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      {/* Display filtered programs */}
      <div className="program-list">
        {filteredPrograms.length > 0 ? (
          filteredPrograms.map((program) => (
            <div key={program.id} className="program-card">
              <h3>{program.programName}</h3>
              <p><strong>Training Goal:</strong> {program.trainingGoal}</p>
              <p><strong>Experience Level:</strong> {program.experienceLevel}</p>
              <p><strong>Duration:</strong> {program.macrocycleDuration} weeks</p>
              <p><strong>Mesocycles:</strong> {mesocycleCounts[program.id] || 0}</p>
              <p><strong>Frequency:</strong> {program.trainingFrequency} workouts/week</p>
              <p><strong>Exercises Per Workout:</strong> {program.exercisesPerWorkout}</p>
              <p><strong>Program Type:</strong> {program.programType}</p>

              <Link to={`/program-detail/${program.id}`} className="view-details-btn">
                View Details
              </Link>
              <button onClick={() => handleEditProgram(program)} className="edit-btn">Edit</button>
              <button onClick={() => handleDeleteProgram(program.id)} className="delete-btn">Delete</button>
            </div>
          ))
        ) : (
          <p>No programs found. Try adjusting the search or filters.</p>
        )}
      </div>
    </div>
  );
};

export default ProgramDatabase;